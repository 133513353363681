import React, { Component } from 'react';


import {
    greatPlaceCircleStyle
} from './IconStuff';

export default class Marker extends Component {

    onMarkerClick(item) {
        this.props.onMarkerClick(item)
    }

    render() {
        return (
            <div>
                <div className={`mapMarker ${this.props.hasBlog ? 'hasBlogPost' : ''}`} style={greatPlaceCircleStyle}

                    onClick={() => this.onMarkerClick(this.props.item.points[0])}
                >
                    {this.props.text}
                </div>
                {this.props.$hover ?
                    <div className="pulse"/>
                : null}
            </div>
        );
    }
}