import React, {Component} from 'react';
import PropTypes from 'prop-types';


import {
    greatPlaceCircleStyle,
} from './IconStuff';

export default class ClusterMarker extends Component {
    static propTypes = {
        // GoogleMap pass $hover props to hovered components to detect hover it uses
        // internal mechanism, explained in x_distance_hover example
        $hover: PropTypes.bool,
        text: PropTypes.number,
        zIndex: PropTypes.number
    };

    static defaultProps = {};

    render() {
        const {text, hasBlog} = this.props;

        return (
            <div>
                <div className={`mapClusterMarker ${hasBlog ? 'hasBlogPost' : ''}`} style={greatPlaceCircleStyle}>

                </div>
                <span className="mapClusterMarkerText">{text}</span>
                {this.props.$hover ?
                    <div className="pulse"/>
                : null}
            </div>
        );
    }
}