

import {
  FETCH_COMMENTS,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_ERROR,
	DELETE_COMMENT,
	DELETE_COMMENT_SUCCESS,
	DELETE_COMMENT_ERROR,
} from "../constants";

const INITIAL_STATE = {
	loading: false,
	comments: {},
	error: null,
};

function commentsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
    case FETCH_COMMENTS:
      return { ...state, loading: true, error: null };
    case FETCH_COMMENTS_SUCCESS:
      return { ...state, loading: false, comments: action.payload };
    case FETCH_COMMENTS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case DELETE_COMMENT:
      return { ...state, loading: true, error: null };
    case DELETE_COMMENT_SUCCESS:
      const comments = Object.keys(state.comments).filter(comment => comment !== action.payload).map(item => state.comments[item]);
      return { ...state, loading: false, comments};
    case DELETE_COMMENT_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}

export default commentsReducer;