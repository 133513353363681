import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PasswordChangeForm from './PasswordChange';
import withAuthorization from './withAuthorization';

const AccountPage = ({ authUser }) =>
	<div>
		<PasswordChangeForm />
	</div>

const mapStateToProps = (state) => ({
	authUser: state.session.authUser,
});

const authCondition = (authUser) => !!authUser;

export default compose(
	withAuthorization(authCondition),
	connect(mapStateToProps)
)(AccountPage);