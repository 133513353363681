

import {
	FETCH_POSITIONS,
	FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_ERROR,
  FETCH_IMAGES,
  FETCH_IMAGES_SUCCESS,
  FETCH_IMAGES_ERROR,
} from '../constants';

const INITIAL_STATE = {
	loading: false,
	data: {},
  error: null,
  images: {}
};

function trackingReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
    case FETCH_POSITIONS:
      return { ...state, loading: true, error: null };
    case FETCH_POSITIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_POSITIONS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case FETCH_IMAGES:
      return { ...state, loading: true, error: null };
    case FETCH_IMAGES_SUCCESS:
      return { ...state, loading: false, images: action.payload };
    case FETCH_IMAGES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}

export default trackingReducer;