import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from './withAuthorization';
import ReactHyperResponsiveTable from "react-hyper-responsive-table";

import noImage from "../img/no-image.jpeg";

import { onGetBlog, deleteBlog } from "../actions/blog";
import {
	isMobile
} from "react-device-detect";

class AdminBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      progress: 0,
      blogPosts: [],
      currentPage: 1,
      blogPostsPerPage: 200,
      reverseOrder: false
    };
		this.handleClick = this.handleClick.bind(this);
    this.reverseSortOrder = this.reverseSortOrder.bind(this);
    this.deleteBlog = this.deleteBlog.bind(this)
  }

  componentDidMount() {
    this.props.onGetBlog();
  }

  sortByDate(data, sortReverse) {
    return Object.keys(data).sort((a, b) => {
      const bDateOnly = data[b].post_date.substring(0, 10);
      const aDateOnly = data[a].post_date.substring(0, 10);
      const bTimeOnly = data[b].post_date.substring(11, 16);
      const aTimeOnly = data[a].post_date.substring(11, 16);
      if (sortReverse) {
        return (
          aDateOnly.localeCompare(bDateOnly) ||
          aTimeOnly.localeCompare(bTimeOnly)
        );
      } else {
        return (
          bDateOnly.localeCompare(aDateOnly) ||
          bTimeOnly.localeCompare(aTimeOnly)
        );
      }
    });
  }
  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  reverseSortOrder() {
		this.setState({reverseOrder: !this.state.reverseOrder})
  }

  deleteBlog(id) {
    const result = window.confirm("Are you sure you want to delete?");
    if(result) {
      this.props.deleteBlog(id);
    }
  }

  render() {
    const { blog } = this.props;

    const pages = isMobile ? 2 : 5;

    const { currentPage, blogPostsPerPage } = this.state;

    // Logic for displaying images
    const indexOfLastBlog = currentPage * blogPostsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogPostsPerPage;

    const sorted = this.sortByDate(blog, this.state.reverseOrder).map(
      blogData => blog[blogData]
    );
    const currentBlogs = Object.keys(sorted)
      .slice(indexOfFirstBlog, indexOfLastBlog)
      .reduce((result, key) => {
        result[key] = sorted[key];

        return result;
      }, {});

    const totalPages = Math.ceil(currentBlogs.length / blogPostsPerPage);
    const boxes = totalPages > pages ? pages : totalPages;
    const middleBox = Math.ceil(boxes / 2);

    let paginationPagseStart = 1;

    if (currentPage <= middleBox) {
      paginationPagseStart = 1;
    } else if (currentPage + (middleBox - 1) >= totalPages) {
      paginationPagseStart = totalPages - (boxes - 1);
    } else {
      paginationPagseStart = currentPage - (middleBox - 1);
    }

    let paginationPages = 1;

    if (totalPages <= boxes) {
      paginationPages = totalPages;
    } else if (currentPage + (middleBox - 1) >= totalPages) {
      paginationPages = totalPages;
    } else {
      paginationPages = paginationPagseStart + (pages - 1);
    }
    const headers = {
      image: "",
      title: "Title",
      category: "Category",
      author: "Author",
      date: "Created",
      status: "Status",
      edit: "Edit",
      delete: "Delete"
    };

    const rows = Object.keys(currentBlogs).map((blog, index) =>
      Object.assign(
        {},
        {
          name: `${currentBlogs[blog].post_title}-${index}`,
          image: (
            <img
              src={
                currentBlogs[blog].post_images &&
                currentBlogs[blog].post_images.length > 0
                  ? currentBlogs[blog].post_images[0]
                  : noImage
              }
              alt="Vito Corleone"
            />
          ),
          title: currentBlogs[blog].post_title,
          category: currentBlogs[blog].post_category,
          author: currentBlogs[blog].post_author,
          date: currentBlogs[blog].post_date,
          status: currentBlogs[blog].post_status,
          edit: (
            <Link className="link" to={`/edit-blog/${currentBlogs[blog].post_id}`}>edit</Link>
          ),
          delete: (
            <div className="link" onClick={() => this.deleteBlog(currentBlogs[blog].post_id)}>delete</div>
          )
        }
      )
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = paginationPagseStart; i < paginationPages + 1; i++) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? "active" : ""}
          onClick={this.handleClick}
        >
          {number}
        </li>
      );
    });
    const keyGetter = row => row.name;
    return (
      <div className="admin-panel">
        <div className="leftFiller" />
        <div className="container-admin">
          <div className="row">
            <div className="col-xs-12">
              <div className="box-row">
                <h1>Blog Posts</h1>
                {this.state.blogPosts.length > 0 &&
                  this.state.blogPosts.map((image, i) => (
                    <img key={i} src={image} alt="AdminBlog" />
                  ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <Link to="/add-blog" className="btn btn-primary">
                Add new blog
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <ul className="pagination">
                <li
                  className={currentPage !== 1 ? "" : "disabled"}
                  onClick={() =>
                    currentPage !== 1 &&
                    this.setState({ currentPage: currentPage - 1 })
                  }
                >
                  &laquo;
                </li>
                {renderPageNumbers}
                <li
                  className={currentPage !== totalPages ? "" : "disabled"}
                  onClick={() =>
                    currentPage !== totalPages &&
                    this.setState({ currentPage: currentPage + 1 })
                  }
                >
                  &raquo;
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="sort-btn" onClick={() => this.reverseSortOrder()}>{this.state.reverseOrder ? 'Sort ascending' : 'Sort descending'}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <ReactHyperResponsiveTable
                headers={headers}
                rows={rows}
                keyGetter={keyGetter}
                breakpoint={925}
                tableStyling={({ narrow }) =>
                  narrow ? "narrowtable" : "widetable"
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <ul className="pagination">
                <li
                  className={currentPage !== 1 ? "" : "disabled"}
                  onClick={() =>
                    currentPage !== 1 &&
                    this.setState({ currentPage: currentPage - 1 })
                  }
                >
                  &laquo;
                </li>
                {renderPageNumbers}
                <li
                  className={currentPage !== totalPages ? "" : "disabled"}
                  onClick={() =>
                    currentPage !== totalPages &&
                    this.setState({ currentPage: currentPage + 1 })
                  }
                >
                  &raquo;
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  session: state.session,
  blog: state.blog.data
});

const mapDispatchToProps = {
  onGetBlog,
  deleteBlog
};

const authCondition = authUser => !!authUser;

export default compose(
	withAuthorization(authCondition),
	connect(mapStateToProps, mapDispatchToProps)
)(AdminBlog);
