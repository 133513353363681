import { PureComponent } from 'react';

export default class Polyline extends PureComponent {

  componentWillUpdate() {
    this.line.setMap(null)
  }

  componentWillUnmount() {
    this.line.setMap(null)
  }

  getPaths() {
    const { points } = this.props;

    return points;
  }

  render() {
    const Polyline = this.props.maps.Polyline

    const renderedPolyline = this.renderPolyline()
    const paths = { path: this.getPaths() }

    this.line = new Polyline(Object.assign({}, renderedPolyline, paths))

    this.line.setMap(this.props.map)

    return null
  }

  renderPolyline() {
    return {
      geodesic: true,
      strokeColor: '#2476e2' || '#ffffff',
      strokeOpacity: 1,
      strokeWeight: 4
    }
  }

}
