import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import withAuthorization from './withAuthorization';
import { storage } from "../firebase/firebase";
import FileUploader from "react-firebase-file-uploader";

import { onGetImages, uploadImage,onRemoveImage } from '../actions/tracking';
import { isMobile } from "react-device-detect";

class Media extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isUploading: false,
			progress: 0,
			images: [],
			currentPage: 1,
			imagesPerPage: 20,
			selectedImages: []
		}
		this.handleClick = this.handleClick.bind(this);
		this.selectImage = this.selectImage.bind(this);
	}

	componentDidMount() {
		this.props.onGetImages();
	}

	componentWillReceiveProps(nextProps){
		if(this.props.images !== nextProps.images) {
			const pages = isMobile ? 2 : 5
			const totalPages = Math.ceil(Object.keys(this.state.images).length / this.state.imagesPerPage);
			const boxes = totalPages > pages ? pages : totalPages;
			const middleBox = Math.ceil(boxes / 2);
			this.setState({images: nextProps.images, pages, totalPages, boxes, middleBox})
		}
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id)
		});
	}
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
  };
  handleUploadSuccess = async filename => {
		this.setState({ image: filename, progress: 100, isUploading: false });
		this.props.uploadImage(filename);
	};

	removeImage(image, title) {
		this.props.onRemoveImage(image, title);
	}

	selectImage(selectedImage) {
		if (this.state.selectedImages.indexOf(selectedImage.title) > -1) {
			const images = this.state.selectedImages.filter(
				image => image !== selectedImage.title
			)
      this.setState({
        selectedImages: images
      });
    } else {
      this.setState({ selectedImages: [...this.state.selectedImages, selectedImage.title] });
    }
		this.props.onSelectImage(selectedImage)
	}

	renderImages() {
		const indexOfLastImage = this.state.currentPage * this.state.imagesPerPage;
		const indexOfFirstImage = indexOfLastImage - this.state.imagesPerPage;



			const currentImages = Object.keys(this.state.images).slice(indexOfFirstImage, indexOfLastImage).reduce((result, key) => {
				result[key] = this.state.images[key];

				return result;
			}, {});

		return Object.keys(currentImages).map((image, index) => {
			return (
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={index}>
					<div className={`box-row ${this.props.onSelectImage ? 'add-blog-image' : 'edit-blog-image'} ${this.state.selectedImages.indexOf(currentImages[image].title) > -1 ? 'selected' : ''}`} style={{ height: "300px", minWidth: '300px' }}>
						{this.props.onSelectImage ?
						<React.Fragment>
						<img src={currentImages[image].url} alt={`select-${index}`} style={{ width: "100%" }} onClick={() => this.selectImage(currentImages[image])}/>

						{this.state.selectedImages.indexOf(currentImages[image].title) > -1 &&
							<span className="selectedImage">Selected</span>
						}
						</React.Fragment>
						:
						<img src={currentImages[image].url} alt={`select-${index}`} style={{ width: "100%"}} onClick={(evt) => this.removeImage(image, currentImages[image].title)}/>
						}
					</div>
						</div>
					);
		});
	}

	renderPageNumbers() {
		let paginationPagseStart = 1;

		if (this.state.currentPage <= this.state.middleBox) {
			paginationPagseStart = 1;
		} else if (this.state.currentPage + (this.state.middleBox - 1) >= this.state.totalPages) {
			paginationPagseStart = this.state.totalPages - (this.state.boxes - 1);
		} else {
			paginationPagseStart = this.state.currentPage - (this.state.middleBox - 1);
		}

		let paginationPages = 1;

		if (this.state.totalPages <= this.state.boxes) {
			paginationPages = this.state.totalPages;
		} else if (this.state.currentPage + (this.state.middleBox - 1) >= this.state.totalPages) {
			paginationPages = this.state.totalPages;
		} else {
			paginationPages = paginationPagseStart + (this.state.pages-1);
		}
		const pageNumbers = [];
		  for (let i = paginationPagseStart; i < paginationPages + 1; i++) {
			pageNumbers.push(i);
		}
		pageNumbers.map(number => {
			return (
				<li
					key={number}
					id={number}
					className={this.statecurrentPage === number ? "active" : ""}
					onClick={this.handleClick}
				>
					{number}
				</li>
			);
		});
	}

  render() {
	const { images } = this.state;

		return (
			<div className={this.props.imageSelection ? "" : "admin-panel"}>
				{!this.props.imageSelection && <div className="leftFiller" />}

        <div className="container-admin">
          <div className="row">
            <div className="col-xs-12">
              <div className="box-row">
                <h1>Media</h1>
                {this.state.isUploading && (
                  <p>Progress: {this.state.progress}</p>
                )}
                {this.state.images.length > 0 &&
                  this.state.images.map((image, i) => (
                    <img key={i} src={image} alt="Media" />
                  ))}
                <FileUploader
                  accept="image/*"
                  name="images"
                  randomizeFilename
                  multiple
                  storageRef={storage.ref("")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <ul className="pagination">
                <li
                  className={this.state.currentPage !== 1 ? "" : "disabled"}
                  onClick={() =>
                    this.state.currentPage !== 1 &&
                    this.setState({ currentPage: this.state.currentPage - 1 })
                  }
                >
                  &laquo;
                </li>
                {this.renderPageNumbers()}
                <li
                  className={this.stateurrentPage !== this.statetotalPages ? "" : "disabled"}
                  onClick={() =>
                    this.statecurrentPage !== this.statetotalPages &&
                    this.setState({ currentPage: this.statecurrentPage + 1 })
                  }
                >
                  &raquo;
                </li>
              </ul>
            </div>
					</div>
					{images && Object.keys(images).length > 0 &&
						<div className="row">{this.renderImages()}</div>
					}
          <div className="row">
            <div className="col-xs-12 text-center">
              <ul className="pagination">
                <li
                  className={this.state.currentPage !== 1 ? "" : "disabled"}
                  onClick={() =>
                    this.state.currentPage !== 1 &&
                    this.setState({ currentPage: this.state.currentPage - 1 })
                  }
                >
                  &laquo;
                </li>
                {this.renderPageNumbers()}
                <li
                  className={this.statecurrentPage !== this.statetotalPages ? "" : "disabled"}
                  onClick={() =>
                    this.statecurrentPage !== this.statetotalPages &&
                    this.setState({ currentPage: this.state.currentPage + 1 })
                  }
                >
                  &raquo;
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
	session: state.session,
	images: state.tracking.images,
	foo: ownProps.foo
});

const mapDispatchToProps = {
	onGetImages,
	uploadImage,
	onRemoveImage
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(Media);
