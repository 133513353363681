import { combineReducers } from 'redux';
import sessionReducer from './session';
import trackingReducer from './tracking';
import blogReducer from './blog';
import commentsReducer from './comments';

const rootReducer = combineReducers({
  session: sessionReducer,
  tracking: trackingReducer,
  blog: blogReducer,
  comments: commentsReducer
});

export default rootReducer;