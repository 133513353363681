import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from './withAuthorization';
class AddNews extends Component {
	render() {
		return <div className="admin-panel">
        <div className="section">
          <h1>Add News</h1>
        </div>
      </div>;
	}
}


const mapStateToProps = state => ({
	session: state.session,
});

const mapDispatchToProps = {
};

const authCondition = authUser => !!authUser;

export default compose(
	withAuthorization(authCondition),
	connect(mapStateToProps, mapDispatchToProps)
)(AddNews);
