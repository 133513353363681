import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import withAuthorization from "./withAuthorization";
import RichTextEditor from "react-rte";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import Select from 'react-select';
import { onGetUserData } from "../actions/session";
import { onGetCategories } from "../actions/blog";
import Media from './Media';

import { db } from '../firebase';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

class EditBlog extends Component {
	constructor(props) {
		super(props);
		this.state = {
    loading: false,
    blogId: "",
    blogAuthor: "",
    blogUrl: "",
		blogTitle: "",
    blogStatus: "",
    blogDate: "",
    blogCategory: {value: 'Uncategorized', label: 'Uncategorized'},
		blogContent: RichTextEditor.createEmptyValue(),
    modalIsOpen: false,
    cetegoryOptions: [],
    images: []
    };
    this.removeImage = this.removeImage.bind(this);
		this.onHandleChange = this.onHandleChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelectImage = this.onSelectImage.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
		// references are now sync'd and can be accessed.
		// this.subtitle.style.color = '#f00';
  }
  pad(n) { return n < 10 ? '0' + n : n }

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	componentDidMount() {
    this.props.onGetCategories();
    if (
      this.props.match.params.id ||
      (this.props.history.location.state && this.props.history.location.state.id)
    ) {
      this.setState({ loading: true });
      const id = this.props.match.params.id || this.props.history.location.state.id
      db.onceGetBlogById(id)
        .then(snapshot => {
          return this.setState({
            blogId: snapshot.val().post_id,
            blogAuthor: snapshot.val().post_author,
            blogUrl: snapshot.val().post_url,
            images: snapshot.val().post_images
              ? snapshot.val().post_images
              : [],
            blogTitle: snapshot.val().post_title,
            blogStatus: snapshot.val().post_status,
            blogCategory: {
              value: snapshot.val().post_category,
              label: snapshot.val().post_category
            },
            blogDate: snapshot
              .val()
              .post_date.substring(0, 16)
              .replace(" ", "T"),
            blogContent: RichTextEditor.createValueFromString(
              snapshot.val().post_content,
              "html"
            ),
            categoryError: false,
            loading: false,
            saveSuccess: false,
            saveError: false
          });
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    } else {
      const date = new Date();
      this.props
        .onGetUserData(this.props.session.authUser.uid)
        .then(snapshot => {
          return this.setState({
            blogAuthor: `${this.props.session.user.firstName} ${
              this.props.session.user.lastName
            }`,
            blogDate: `${date.getFullYear()}-${this.pad(
              date.getMonth() + 1
            )}-${this.pad(date.getDate())}T${this.pad(
              date.getHours()
            )}:${this.pad(date.getMinutes())}`
          });
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.history.location.state && nextProps.history.location.state.id)
    {
      this.setState({ loading: true });
      const id = this.props.history.location.state.id
      db.onceGetBlogById(id)
        .then(snapshot => {
          return this.setState({
            blogId: snapshot.val().post_id,
            blogAuthor: snapshot.val().post_author,
            blogUrl: snapshot.val().post_url,
            images: snapshot.val().post_images
              ? snapshot.val().post_images
              : [],
            blogTitle: snapshot.val().post_title,
            blogStatus: snapshot.val().post_status,
            blogCategory: {
              value: snapshot.val().post_category,
              label: snapshot.val().post_category
            },
            blogDate: snapshot
              .val()
              .post_date.substring(0, 16)
              .replace(" ", "T"),
            blogContent: RichTextEditor.createValueFromString(
              snapshot.val().post_content,
              "html"
            ),
            categoryError: false,
            loading: false,
            saveSuccess: false,
            saveError: false
          });
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    }

    const options =  Object.keys(nextProps.blog.categories).map(category => Object.assign({value:nextProps.blog.categories[category].category, label:nextProps.blog.categories[category].category}))

    this.setState({options})
  }

  onSaveBlog(evt, status) {
    evt.preventDefault();
    evt.target.reportValidity();
    // Controlled component. Value is in state.
    if (!this.state.blogCategory.value) { this.setState(
                                            {
                                              categoryError: true
                                            }
                                          ); }

    const {
      blogId,
      blogAuthor,
      blogUrl,
      blogTitle,
      blogDate,
      blogCategory,
      blogContent,
      images
    } = this.state;
    if (
      this.props.match.params.id ||
      (this.props.history.location.state &&
        this.props.history.location.state.id)
    ) {
      db.doUpdateBlogPost(
        blogId,
        blogTitle,
        blogAuthor,
        blogContent.toString("html"),
        blogDate.replace("T", " "),
        blogDate.replace("T", " "),
        status,
        blogCategory.value,
        blogUrl,
        images
      );
    } else {
      db.doAddBlogPost(
        blogTitle,
        blogAuthor,
        blogContent.toString("html"),
        blogDate.replace("T", " "),
        blogDate.replace("T", " "),
        status,
        blogCategory.value,
        blogUrl,
        images
      )
        .then(res => {
          this.setState({ blogId: res, saveSuccess: true})
          this.props.history.push({
            pathname: `/edit-blog/${res}`,
            state: { id: res }
          })
        }
        )
        .catch(err => this.setState({ saveError: true }));
    }
  }

	onSelectImage(selectedImage) {
    if (this.state.images.indexOf(selectedImage.url) > -1) {
      this.setState({
        images: this.state.images.filter(
          image => image !== selectedImage.url
        )
      });
    } else {
      this.setState({ images: [...this.state.images, selectedImage.url] });
    }
	}

  onHandleChange(value, key) {
		this.setState({ [key]: value });
	}

	onChange(value) {
		this.setState({ blogContent: value });
  }

  removeImage(evt, image) {
    evt.stopPropagation();
    const images = this.state.images.filter(item => item !== image);
    this.setState({images})
  }
	render() {
    const {
      blogTitle,
      // blogStatus,
      blogDate,
      blogCategory,
      blogContent,
      options
    } = this.state;
		return (
      <div className="admin-panel">
        <div className="leftFiller" />
        <div className="container-admin">
          <div className="row">
            {this.state.loading ? (
              <div className="loading">
                <Loader
                  type="Grid"
                  color="#005698"
                  height={60}
                  width={60}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="col-xs-12">
                  <button
                    className="form-btn"
                    type="button"
                    onClick={this.openModal}
                  >
                    Select or Upload Images
                  </button>
                </div>
                {this.state.images.map((image, i) => {
                  return (
                    <div className="col-xs-4 col-md-3 col-lg-2" key={i}>
                      <div className="box-row edit-blog-image" style={{ height: "150px" }}>
                        <img
                          onClick={(evt) => this.removeImage(evt, image)}
                          src={image}
                          alt="Select"
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="col-xs-12">
                  <form
                    className="form-card"
                  >
                    <fieldset className="form-fieldset">
                      <div className="form-element form-input">
                        <input
                          id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                          className="form-element-field"
                          placeholder="Enter a blog title"
                          type="input"
                          value={blogTitle}
                          required
                          onChange={evt =>
                            this.onHandleChange(
                              evt.target.value,
                              "blogTitle"
                            )
                          }
                        />
                        <div className="form-element-bar" />
                        <label
                          className="form-element-label"
                          htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                        >
                          Blog title
                        </label>
                      </div>
                      <div className="form-element form-input">
                        <input
                          id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                          className="form-element-field"
                          placeholder="Enter a blog date"
                          type="datetime-local"
                          value={blogDate}
                          required
                          onChange={evt =>
                            this.onHandleChange(
                              evt.target.value,
                              "blogDate"
                            )
                          }
                        />
                        <div className="form-element-bar" />
                        <label
                          className="form-element-label"
                          htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                        >
                          Blog date
                        </label>
                      </div>
                      <div className="form-element form-select">
                        <Select
                          value={blogCategory}
                          name="blogCategory"
                          required
                            styles={{
                              control: (provided, state) => (this.state.categoryError ? {
                                ...provided, borderColor: 'red',
                              } : provided)
                            }}
                          isSearchable={false}
                          onChange={val =>
                            this.onHandleChange(val, "blogCategory")
                          }
                          options={options}
                        />
                        <div className="form-element-bar" />
                        <label
                          className="form-element-label"
                          htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                        >
                          Blog Category
                        </label>
                      </div>
                      <div className="form-element form-textarea">
                        <RichTextEditor
                          value={blogContent}
                          onChange={this.onChange}
                        />

                        <div className="form-element-bar" />
                        <label
                          className="form-element-label"
                          htmlFor="field-3naeph-0f3yuw-x153ph-dzmahy-qhkmgm"
                        >
                          Blog content
                        </label>
                      </div>
                    </fieldset>
                    {this.state.saveSuccess && <div>Blog post saved successfully</div>}
                    <div className="form-actions">
                      <button
                        className="form-btn-cancel -nooutline"
                        type="submit"
                        onClick={(evt) => this.onSaveBlog(evt, "draft")}
                      >
                        Save Draft
                      </button>

                      <button
                        className="form-btn -nooutline"
                        type="submit"
                        onClick={(evt) => this.onSaveBlog(evt, "publish")}
                      >
                        Publish
                      </button>
                    </div>
                  </form>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
        <button className="close" onClick={this.closeModal}>close</button>
          <Media
            {...this.props}
            onSelectImage={this.onSelectImage}
            imageSelection={true}
          />
        </Modal>
      </div>
    );
	}
}

const mapStateToProps = state => ({
	session: state.session,
  blog: state.blog,
});

const mapDispatchToProps = {
  onGetUserData,
  onGetCategories
};

const authCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withRouter(EditBlog));