import {
	FETCH_COMMENTS,
	FETCH_COMMENTS_SUCCESS,
	FETCH_COMMENTS_ERROR,
	DELETE_COMMENT,
	DELETE_COMMENT_SUCCESS,
	DELETE_COMMENT_ERROR
} from '../constants';

import { db } from "../firebase";

export const fetchCommentsBegin = payload => ({ type: FETCH_COMMENTS });
export const fetchCommentsSuccess = payload => ({ type: FETCH_COMMENTS_SUCCESS, payload });
export const fetchCommentsError = payload => ({ type: FETCH_COMMENTS_ERROR, payload });

export const deleteCommentBegin = payload => ({ type: DELETE_COMMENT });
export const deleteCommentSuccess = payload => ({ type: DELETE_COMMENT_SUCCESS, payload });
export const deleteCommentError = payload => ({ type: DELETE_COMMENT_ERROR, payload });

export function onGetComments() {
	return dispatch => {
	dispatch(fetchCommentsBegin());
	return db
		.onceGetComments()
		.then(snapshot => {

		return dispatch(fetchCommentsSuccess(snapshot.val() ? snapshot.val() : {}));
		})
		.catch(error => {
		return dispatch(fetchCommentsError(error));
		});
	};
}

export function onUpdateComments(comment_id, blog_id, name, comment, approved, comment_date) {
	return dispatch => {
	dispatch(fetchCommentsBegin());
	return db
		.doUpdateComment(comment_id, blog_id, name, comment, approved, comment_date)
		.then(snapshot => {
			return dispatch(onGetComments());
		})
		.catch(error => {
		return dispatch(fetchCommentsError(error));
		});
	};
}

export function deleteComment(id) {
	return dispatch => {
		dispatch(deleteCommentBegin());
		db
			.onDeleteComment(id);
		return dispatch(deleteCommentSuccess(id));
	};
}