import React from 'react';
import { Link } from "react-router-dom";

import forkLift from '../img/logo-white.png';

const NotFound = () => (
	<div
    className="top-content"
    style={{ position: "relative", minHeight: "calc(100vh - 110px)" }}
	>
		<div className="inner-bg four-o-four">
			<img src={forkLift} alt="404 Page" width="70%" />
			<h1>404. Page not found.</h1>
			<p>We've searched high and low, but this page does not exist.</p>
			<Link to="/">Back to the homepage</Link>
		</div>
	</div>
);

export default NotFound;