import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import Loader from "react-loader-spinner";
// import withAuthorization from './withAuthorization';
import { onGetComments, deleteComment, onUpdateComments } from "../actions/comments";
// import data from '../json/blogposts.json';
// import { db } from "../firebase";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: ""
    };
  }
  componentDidMount() {
    this.props.onGetComments();
  }

  approve(commentId, commentDetails, status) {
    this.props.onUpdateComments(commentId, commentDetails.blog_id, commentDetails.name, commentDetails.comment, status, commentDetails.comment_date)
  }

  delete(commentId) {
    this.props.deleteComment(commentId)
  }

  render() {
    const { comments } = this.props;

    return (
      <React.Fragment>
          <div className="row">
            <div className="col-xs-12">
              <h1>New comments</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="box-row border-box-row box-filters">
                <div className="messages-wrapper">
                  {comments &&
                    Object.keys(comments).filter(comment => comments[comment].approved === false).map((comment, i) => {
                      return <div key={i} className="message-container">
                      <div className="message to admin">{comments[comment].comment}
                        <div className="message-date">{comments[comment].comment_date}</div>
                      </div>
                      <div className="message-author to admin">{comments[comment].name}</div>
                      <div className="message-approve"><span onClick={() => this.approve(comment, comments[comment], true)}>approve</span> / <span onClick={() => this.delete(comment)}>delete</span></div>
                    </div>;
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h1>Approved comments</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="box-row border-box-row box-filters">
                <div className="messages-wrapper">
                  {comments &&
                    Object.keys(comments).filter(comment => comments[comment].approved).map((comment, i) => {
                      return <div key={i} className="message-container">
                      <div className="message to admin">{comments[comment].comment}
                        <div className="message-date">{comments[comment].comment_date}</div>
                      </div>
                      <div className="message-author to admin">{comments[comment].name}</div>
                      <div className="message-approve"><span className="unapprove" onClick={() => this.approve(comment, comments[comment], false)}>un-approve</span> / <span onClick={() => this.delete(comment)}>delete</span></div>
                    </div>;
                    })}
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
	comments: state.comments.comments,
});

const mapDispatchToProps = {
  onGetComments,
  deleteComment,
  onUpdateComments
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(Comments);

