import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '../firebase';

const PasswordForgetPage = () =>
	<div>
		<h1>PasswordForget</h1>
		<PasswordForgetForm />
	</div>

const byPropKey = (propertyName, value) => () => ({
	[propertyName]: value,
});

const INITIAL_STATE = {
	email: '',
	error: null,
	success: false,
};

class PasswordForgetForm extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
	}

	onSubmit = (event) => {
		const { email } = this.state;

		auth.doPasswordReset(email)
			.then(() => {
				this.setState(() => ({ ...INITIAL_STATE }));
				this.setState(byPropKey('success', {message: 'Please check your email and follow the password reset instructions'}));
			})
			.catch(error => {
				this.setState(byPropKey('error', error));
			});

		event.preventDefault();
	}

	render() {
		const {
			email,
			error,
			success,
		} = this.state;

		const isInvalid = email === '';

		return (
			<form onSubmit={this.onSubmit} className="form-horizontal">
				<div className="form-group">
					<label className="control-label col-sm-3" htmlFor="firstName">Enter email:</label>
					<div className="col-sm-9">
						<input
							value={this.state.email}
							className="form-control"
							onChange={event => this.setState(byPropKey('email', event.target.value))}
							type="text"
							placeholder="Email Address"
						/>
					</div>
				</div>
				<div className="form-group">
					<div className="col-sm-6 col-sm-offset-3">
						<button 
							disabled={isInvalid} 
							type="submit"
							className="btn btn-primary btn-block"
						>
							Submit
						</button>
					</div>
				</div>
				{error &&
					<div className="alert alert-danger" role="alert">
						<strong>{error.message}</strong>
					</div>
				}
				{success &&
					<div className="alert alert-success" role="alert">
						<strong>{success.message}</strong>
					</div>
				}
			</form>
		);
	}
}

const PasswordForgetLink = () =>
	<p>
		<Link to="/pw-forget">Forgot Password?</Link>
	</p>

export default PasswordForgetPage;

export {
	PasswordForgetForm,
	PasswordForgetLink,
};