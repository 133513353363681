import React from 'react';
import {
	Route,
	Switch,
} from 'react-router-dom';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import Footer from "./Footer";
import Landing from "./Landing";
import SignInPage from "./SignIn";
import PasswordForgetPage from "./PasswordForget";
import HomePage from "./Home";
import AccountPage from "./Account";
import SignUp from "./SignUp";
import Tracking from "./Tracking";
import TrackingLatest from "./TrackingLatest";
import AddNews from "./AddNews";
import Media from "./Media";
import AdminBlog from "./AdminBlog";
import NotFound from "./NotFound";
import EditBlog from "./EditBlog";
import CategoryPage from "./CategoryPage";
// import * as routes from "../constants/routes";

const AppRouter = () => (
  <Switch>
    <Route exact path="/" component={Landing} />
    <Route exact path="/sign-in" component={SignInPage} />
    <Route exact path="/pw-forget" component={() => <PasswordForgetPage />} />
    <Route exact path="/home" component={HomePage} />
    <Route exact path="/add/news" component={AddNews} />
    <Route exact path="/my-account" component={AccountPage} />
    <Route exact path="/tracking" component={Tracking} />
    <Route exact path="/admin-tracking" component={TrackingLatest} />
    <Route exact path="/admin-blog" component={AdminBlog} />
    <Route exact path="/add-blog" component={EditBlog} />
    <Route exact path="/edit-blog/:id" component={EditBlog} />
    <Route exact path="/sign-up" component={SignUp} />
    <Route exact path="/media" component={Media} />
    <Route exact path="/categories" component={CategoryPage} />
    <Route exact path="*" component={NotFound} />
  </Switch>
);

const mapStateToProps = (state) => ({
  authUser: state.session.authUser
});

export default withRouter(connect(mapStateToProps)(AppRouter));