import React, { Component } from 'react';
import Cats from './Categories';

class Categories extends Component {

  render() {

    return (
      <div className="admin-panel">
        <div className="leftFiller" />
        <div className="container-admin">
          <div className="row">
            <div className="col-xs-12">
              <h1>Categories</h1>
            </div>
          </div>
					<Cats />
        </div>
      </div>
    );
  }
}

export default Categories;

