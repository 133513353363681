import React, { Component } from 'react';
import { auth } from '../firebase';

const byPropKey = (propertyName, value) => () => ({
	[propertyName]: value,
});

const INITIAL_STATE = {
	passwordOne: '',
	passwordTwo: '',
	error: null,
};

class PasswordChangeForm extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
	}

	onSubmit = (event) => {
		const { passwordOne } = this.state;

		auth.doPasswordUpdate(passwordOne)
			.then(() => {
				this.setState(() => ({ ...INITIAL_STATE }));
			})
			.catch(error => {
				this.setState(byPropKey('error', error));
			});

		event.preventDefault();
	}

	render() {
		const {
			passwordOne,
			passwordTwo,
			error,
		} = this.state;

		const isInvalid =
			passwordOne !== passwordTwo ||
			passwordOne === '';

		return (
			<div className="top-content">
				<div className="inner-bg">
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="form-box">
									<h2>Password Settings</h2>
									<form className="form-horizontal" onSubmit={this.onSubmit}>
										<div className="form-group">
											<label className="control-label col-md-3" htmlFor="firstName">New password:</label>
											<div className="col-md-3">
												<input
													value={passwordOne}
													className="form-control"
													onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
													type="password"
													placeholder="New Password"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="control-label col-md-3" htmlFor="firstName">Confirm password:</label>
											<div className="col-md-3">
												<input
													value={passwordTwo}
													className="form-control"
													onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
													type="password"
													placeholder="Confirm New Password"
												/>
											</div>
										</div>
										<div className="form-group">
											<div className="col-md-6">
												<button className="btn btn-primary btn-block" disabled={isInvalid} type="submit">
													Reset My Password
												</button>
											</div>
										</div>

										{error &&
											<div className="alert alert-danger" role="alert">
												<strong>{error.message}</strong>
											</div>
										}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PasswordChangeForm;