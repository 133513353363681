import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import TrackLatest from './TrackingLatest';
class Tracking extends Component {
	render() {
		return (
			<TrackLatest location={this.props.location} history={this.props.history} spectator={true}/>
    );
	}
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Tracking);