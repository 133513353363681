import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ReCaptcha } from 'react-recaptcha-v3'
import { onGetBlog } from '../actions/blog';
import { onGetComments } from "../actions/comments";
import Loader from "react-loader-spinner";
import ImageGallery from "react-image-gallery";
import {
  faComments
} from "@fortawesome/free-solid-svg-icons";
import {
  faComments as faCommentsOutline,
} from  "@fortawesome/free-regular-svg-icons";
import {
    faChevronUp, faChevronDown} from  "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { db } from "../firebase";

class Landing extends Component {

  constructor(props) {
    super(props)
    this.filter = this.filter.bind(this);
    this.getCount = this.getCount.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.state = {
      loading: false,
      originalBlog: null,
      blog: null,
      sorted: null,
      sortReverse: false,
      uniqueFilters: {},
      activeTop: null,
      activeList: null,
      setFixed: false,
      blogFixed: false,
      commentsActive: null,
      commentEnabled: false,
      comment: "",
      commentName: "",
      comments: {},
      visible: 10,
      scrollTop: 0,
      filterUp: false,
      subscribeUp: false,
      emailAdd: '',
      subscribeSuccess: false
    }
  }

	componentDidMount(){
    this.props.onGetBlog();
    this.props.onGetComments();
    window.addEventListener('scroll', _.debounce(() =>{// lodash debounce method.
      let supportPageOffset = window.pageXOffset !== undefined;
      let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
      let scroll = {
        x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
        y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
      };

      if(scroll.y > 160){
          this.setState({setFixed: true});//change the attribute.
      } else {
        this.setState({setFixed: false});
      }

      if(scroll.y > 0){
        this.setState({blogFixed: true});//change the attribute.
    } else {
      this.setState({blogFixed: false});
    }

  }, 0));
  if (this.captcha) {
      this.captcha.reset();
  }
  }

  componentDidUpdate() {
    if(this.state.scrollTop > 0) {
      window.scrollTo(0, this.state.scrollTop);
      this.setState({scrollTop: 0})
    }
  }


  componentWillReceiveProps(nextProps) {
    this.setState({originalBlog: nextProps.blog.data, blog: nextProps.blog.data, sorted: this.sortByDate(nextProps.blog.data, false), comments: nextProps.comments.comments})

    const uniqueFilters = {
      categories: [],
      years: [],
      months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    };
    Object.keys(nextProps.blog.data).map(blog => {
        if (uniqueFilters.categories.indexOf(nextProps.blog.data[blog].post_category) === -1) {
          uniqueFilters.categories.push(nextProps.blog.data[blog].post_category)
        }
        if (uniqueFilters.years.indexOf(nextProps.blog.data[blog].post_date.substring(0,4)) === -1) {
          uniqueFilters.years.push(nextProps.blog.data[blog].post_date.substring(0,4))
        }
        return true;
    });
    this.setState({uniqueFilters})
  }

  onLoadRecaptcha() {
    if (this.captcha) {
        this.captcha.reset();
    }
}

  verifyCallback(recaptchaToken) {
    this.setState({commentEnabled: true})
  }

  convertDate(date) {
		const year = date.substring(0, 4);
		const month = date.substring(5,7);
		const day = date.substring(8, 10);
		return `${day}-${month}-${year}`;
  }

  getCount(key, value, dateFrom, dateTo) {
    return Object.keys(this.state.originalBlog).reduce((n, val) => {
      if(dateFrom || dateTo) {
        return n +(this.state.originalBlog[val][key].substring(dateFrom, dateTo) === value && this.state.originalBlog[val].post_status === "publish")
      }
      return n + (this.state.originalBlog[val][key] === value && this.state.originalBlog[val].post_status === "publish")
    }, 0);
  }

  async filter(key, value, dateFrom, dateTo) {
    const self = this;
    const filtered = Object.keys(this.state.originalBlog).filter(item => {
      if(dateFrom || dateTo) {
        return this.state.originalBlog[item][key].substring(dateFrom, dateTo) === value
      }
      return this.state.originalBlog[item][key] === value
    }).map(function(obj) {
      return self.state.originalBlog[obj];
    });;
    this.setState({ blog: filtered, sorted: this.sortByDate(filtered, false)})
  }

  getMonth(monthNumber) {
    switch(monthNumber) {
      case "01":
        return "January"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "September"
      case "10":
        return "October"
      case "11":
        return "November"
      case "12":
        return "December"
      default:
        break;
    }
  }
  htmlDecode(content) {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  pad(n) { return n < 10 ? '0' + n : n }

  sortByDate(data, sortReverse) {
    return Object.keys(data).sort((a, b) => {
      const bDateOnly = data[b].post_date.substring(0,10);
      const aDateOnly = data[a].post_date.substring(0, 10);
      const bTimeOnly = data[b].post_date.substring(11, 16);
      const aTimeOnly = data[a].post_date.substring(11, 16);
      if(sortReverse) {
        return (
          aDateOnly.localeCompare(bDateOnly) ||
          aTimeOnly.localeCompare(bTimeOnly)
        );
      } else {
        return (
          bDateOnly.localeCompare(aDateOnly) ||
          bTimeOnly.localeCompare(aTimeOnly)
        );
      }
    });
  }

  fliterComments(blogId) {
    const commentsArray =  Object.keys(this.state.comments).filter(comment => {
      return this.state.comments[comment].blog_id === blogId && this.state.comments[comment].approved
    }).reduce((obj, key) => {
      obj[key] = this.state.comments[key];
      return obj;
    }, {});
    return commentsArray;
  }


  loadMore() {
    this.setState({scrollTop:  window.pageYOffset});
    this.setState((prev) => {
      return {visible: prev.visible + 4};
    });
  }

  submitComment(evt, blogId) {
    evt.preventDefault();
    const approved = false;
    const date = new Date();
    const stringDate = `${this.pad(date.getDate())}-${this.pad(
      date.getMonth()+1
    )}-${date.getFullYear()} ${this.pad(
      date.getHours()
    )}:${this.pad(date.getMinutes())}`;
    if (this.state.commentEnabled) {
      db.doCreateComment(
        blogId,
        this.state.commentName,
        this.state.comment,
        this.state.commentName.toLowerCase() === 'keith' || this.state.commentName.toLowerCase() === 'diane' || this.state.commentName.toLowerCase() === 'diz' ? true : approved,
        stringDate
      )
      .then(res => {
        this.setState({commentsActive: null,
          commentEnabled: false,
          comment: "",
          commentName: "",});
          (this.state.commentName.toLowerCase() !== 'keith' || this.state.commentName.toLowerCase() !== 'diane' || this.state.commentName.toLowerCase() !== 'diz') && alert('comment sent for approval');
      }
      )
      .catch(err => this.setState({ saveError: true }));
    } else {

    }

  }

  onHandleChange(value, key) {
    this.setState({[key]: value});
  }

  toggleFilter () {
    this.setState({filterUp: !this.state.filterUp})
  }
  toggleSubscribe () {
    this.setState({subscribeUp: !this.state.subscribeUp})
  }

  handleChange(evt) {
    console.log(evt.target.value)
    this.setState({emailAdd: evt.target.value})
  }

  async addToSubscribe() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(this.state.emailAdd).toLowerCase())) {
      await db.doCreateEmail(this.state.emailAdd,new Date());
      return this.setState({emailAdd: '', subscribeSuccess: true})
    };

    return console.log('email invalid')
  }


	render() {
    const {sorted, blogFixed} = this.state;
		return (
      <div className="container">
				<div className="row">
					<div className={`col-xs-12 ${blogFixed ? 'blog-padding' : ''}`}>
						<h1>Blog</h1>
          </div>
        </div>
        <div className="row row-reverse-md">
        <div className="col-xs-12 col-md-4 ">
        <div className={`box-row border-box-row box-filters ${this.state.setFixed ? 'filter-fixed' : ''}`}>
            <h1 onClick={() => this.toggleFilter()}>Filter <small style={{float: 'right'}}><FontAwesomeIcon icon={ !this.state.filterUp ? faChevronDown : faChevronUp} /></small></h1>
            {this.state.filterUp &&
            <div>
            {this.state.uniqueFilters.categories  && !this.props.blog.loading &&
              <React.Fragment>
                <h2>Categories</h2>
                <ul>
                  {this.state.uniqueFilters.categories.map(item => {
                    const mainCount = this.getCount('post_category', item);
                    if (parseInt(mainCount, 10) > 0) {
                      return (
                        <li  className={this.state.activeTop === item ? 'active' : ''} key={item} onClick={() => {
                          this.toggleFilter();
                          this.setState({activeTop: this.state.activeTop === item ? null : item, activeList: this.state.activeTop === item ? null : item})
                          this.filter('post_category', item)}
                        }>{item} <small>({mainCount})</small></li>
                      )
                    }
                    return null;
                  })}
                </ul>
              </React.Fragment>
            }
            {this.state.uniqueFilters.categories && !this.props.blog.loading &&
              <React.Fragment>
                <h2>Years</h2>
                <ul>
                  {this.state.uniqueFilters.years.map(item => {
                    const mainCount = this.getCount('post_date', item, 0,4);
                    if (parseInt(mainCount, 10) > 0) {
                      return (
                        <React.Fragment key={item}>
                          <li className={this.state.activeTop === item ? 'active' : ''} onClick={() => {
                            // this.toggleFilter();
                            this.setState({activeTop: this.state.activeTop === item ? null : item, activeList: this.state.activeTop === item ? null : item})
                            this.filter('post_date', item, 0, 4)}
                          }>{item}<small>({mainCount})</small></li>
                          <ul className={`sublist ${this.state.activeList === item ? 'active' : ''}`}>
                          {this.state.uniqueFilters.months.map(month => {
                            const count = this.getCount('post_date', `${item}-${month}`, 0,7);
                            if (parseInt(count, 10) > 0) {
                              return (
                                <li className={this.state.activeTop === month ? 'active' : ''} key={month} onClick={() => {
                                  this.toggleFilter();
                                  this.setState({activeTop: this.state.activeTop === month ? null : month})
                                  this.filter('post_date', `${item}-${month}`, 0, 7)
                                }}>{this.getMonth(month)} <small>({count})</small></li>
                              )
                            }
                            return null;
                          })}
                          </ul>
                        </React.Fragment>
                      )
                    }
                    return null;
                  })}
                </ul>
              </React.Fragment>
              }
            </div>
            }
        </div>
        <div className="box-row border-box-row box-filters">
        <h1 onClick={() => this.toggleSubscribe()}>Subscribe <small style={{float: 'right'}}><FontAwesomeIcon icon={ !this.state.subscribeUp ? faChevronDown : faChevronUp} /></small></h1>
        {this.state.subscribeUp &&
          <div>
          {!this.state.subscribeSuccess && (<div>
          <fieldset className="form-fieldset">
                  <div className="form-element form-input">
                    <input
                      id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                      className="form-element-field"
                      placeholder="Enter your email"
                      type="email"
                      value={this.state.emailAdd}
                      required
                      onChange={(evt) => this.handleChange(evt)}
                    />
                    <div className="form-element-bar" />
                    <label
                      className="form-element-label"
                      htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                    >
                      Email
                    </label>
                  </div>
                </fieldset>
                <div className="form-actions">
                  <button
                    className="form-btn-cancel -nooutline"
                    type="button"
                    onClick={() => this.addToSubscribe()}
                  >
                    ADD
                  </button>
        </div></div>)}
        {this.state.subscribeSuccess && (
          <div>Successfully subscribed</div>
        )}
          </div>}
        </div>
      </div>
          <div className="col-xs-12 col-md-8">
            {this.props.blog.loading ? (
							<div className="loading">
								<Loader type="Grid" color="#005698" height={60} width={60} />
							</div>
            ) : (
              <div className="row">
									{sorted &&
                sorted.length > 0 ? (
                  sorted.slice(0, this.state.visible).map((blog, i) => {
                        if (this.state.blog[blog]
                          .post_status === 'publish') {
                      return (
                        <div
                          className="col-xs-12 p-1"
                          key={i}
                        >
                          <div className="box-row border-box-row">
                            {this.state.blog[blog]
                              .post_images && (
                              <ImageGallery
                              showFullscreenButton={false}
                      showPlayButton={false}
                                items={this.state.blog[
                                  blog
                                ].post_images.map(image =>
                                  Object.assign(
                                    {},
                                    {
                                      original: image,
                                      thumbnail: image
                                    }
                                  )
                                )}
                                showThumbnails={false}
                              />
                            )}
                            <h3 className="post-title">
                              {
                                this.state.blog[blog]
                                  .post_title
                              }
                            </h3>
                            <h2>{
                              this.state.blog[blog]
                                .post_category
                            }</h2>

                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.blog[
                                  blog
                                ].post_content
                              }}
                            />
                            <div className="post-footer">
                              <small className="post-date">
                                {`${this.convertDate(
                                  this.state.blog[blog]
                                    .post_date
                                )} ${this.state.blog[blog].post_date.substring(11, 16)}`}
                              </small>

                              <div
                                className="post-comment-icon"
                                onClick={() => {
                                  // this.props.onGetComments(post_id);
                                  this.setState({commentsActive: this.state.commentsActive === this.state.blog[blog].post_id ? null : this.state.blog[blog].post_id})}
                                }
                              >
                                Comment <FontAwesomeIcon icon={Object.keys(this.fliterComments(this.state.blog[blog]
                                .post_id)).length > 0 ? faComments : faCommentsOutline} style={{color: "#005698", cursor: "pointer"}}/>
                              </div>

                            </div>
                            <div className={`post-comments ${this.state.commentsActive === this.state.blog[blog].post_id ? 'active' : ''}`}>

                              {Object.keys(this.fliterComments(this.state.blog[blog]
                                .post_id)).length > 0 &&
                                <div className="messages-wrapper">
                                {Object.keys(this.fliterComments(this.state.blog[blog]
                                  .post_id)).map((comment, i) => {
                                    return (
                                      <div key={i} className="message-container">
                                        <div className={`message ${this.state.comments[comment].name.toLowerCase() === 'keith' || this.state.comments[comment].name.toLowerCase() === 'diane' || this.state.comments[comment].name.toLowerCase() === 'diz' ? 'from' : 'to'}`}>{this.state.comments[comment].comment}
                                          <div className="message-date">{this.state.comments[comment].comment_date}</div>
                                        </div>
                                        <div className={`message-author ${this.state.comments[comment].name.toLowerCase() === 'keith' || this.state.comments[comment].name.toLowerCase() === 'diane' || this.state.comments[comment].name.toLowerCase() === 'diz' ? 'from' : 'to'}`}>{this.state.comments[comment].name}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                              }
                              <form
                              className="form-card"
                              onSubmit={evt => this.submitComment(evt, this.state.blog[blog].post_id)}>
                              <fieldset className="form-fieldset">
                                <div className="form-element form-input">
                                  <input
                                    id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                                    className="form-element-field"
                                    placeholder="Enter your name"
                                    type="input"
                                    value={this.state.commentName}
                                    required
                                    onChange={evt =>
                                      this.onHandleChange(
                                        evt.target.value,
                                        "commentName"
                                      )
                                    }
                                  />
                                  <div className="form-element-bar" />
                                  <label
                                    className="form-element-label"
                                    htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                                  >
                                    Your name
                                  </label>
                                </div>
                                <div className="form-element form-input">
                                  <textarea
                                    id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                                    className="form-element-field"
                                    placeholder="Write something..."
                                    value={this.state.comment}
                                    required
                                    rows={4}
                                    onChange={evt =>
                                      this.onHandleChange(
                                        evt.target.value,
                                        "comment"
                                      )
                                    }
                                  />
                                  {this.state.commentsActive === this.state.blog[blog].post_id &&
                                    <ReCaptcha
                                        ref={(el) => {this.captcha = el;}}
                                        sitekey="6LfiS6YUAAAAAArqcuqCc1E2ac4iknUf1-aS0nhC"
                                        action='action_name'
                                        size="normal"
                                        render="explicit"
                                        onloadCallback={this.onLoadRecaptcha}
                                        verifyCallback={this.verifyCallback}
                                    />
                                  }
                                  <div className="form-element-bar" />
                                  <label
                                    className="form-element-label"
                                    htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                                  >
                                    Comment
                                  </label>
                                </div>
                              </fieldset>
                              <div className="form-actions" style={{marginTop: '0px'}}>
                                <button
                                  className="form-btn -nooutline"
                                  style={{marginTop: '0px'}}
                                  type="submit"
                                  disabled={!this.state.commentEnabled}
                                >
                                  Comment
                                </button>
                              </div>
                            </form>
                            </div>
                          </div>

                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className="col-xs-12 p-0">
                    <div className="box-row border-box-row">
                      <h3 className="text-center" style={{width: "100%"}}>No posts</h3>
                    </div>
                  </div>
                )}
                <button onClick={this.loadMore} type="button" className="load-more">Load more</button>
              </div>
            )}
					</div>

        </div>
        <div className="scroll-top" onClick={() => window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })}>Top</div>
      </div>
    );
	}
}

const mapStateToProps = state => ({
  blog: state.blog,
  comments: state.comments
});

const mapDispatchToProps = {
  onGetBlog,
  onGetComments
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Landing);