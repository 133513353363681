import { db } from './firebase';

// User API

export const doCreateUser = (id, firstName, lastName, email) =>
	db.ref(`users/${id}`).set({
		firstName,
		lastName,
		email,
	});


export const doCreateEmail = (email, timeStamp) =>
	db.ref('emails/').set({
		email,
		timeStamp
	});



export const doCreateImage = (url, title) =>
         db
           .ref(`images/`)
           .push()
           .set({
             title,
             url
		   });

export const doCreateCategory = (category) =>
	db.ref("categories/").push().set({category});

export const doCreateComment = (blog_id, name, comment, approved, comment_date) => {
	return db
		.ref(`comments/`)
		.push()
		.set({
			blog_id,
			name,
			comment,
			approved,
			comment_date
		});
}

export const doAddPosition = (
         id,
         altitude,
	battery,
         cog,
         gpsAt,
         gpsAtMillis,
         latitude,
		 longitude,
		 sogKmph,
		 sogKnots,
		 txAt,
		 type
       ) =>
         db.ref(`positions/${id}`).set({
           id,
           altitude,
			battery,
           cog,
           gpsAt,
           gpsAtMillis,
           latitude,
		   longitude,
		   sogKmph,
		   sogKnots,
		   txAt,
		   type
		 });

export const doUpdatePosition = (id,blog_id) => {
	return db.ref("positions/").child(id).update({blog_id: blog_id});
}

export const doAddBlogPostLegacy = (
	post_id,
	post_title,
	post_author,
	post_content,
	post_date,
	post_pub_date,
	post_status,
	post_category,
	post_url
) =>
	db.ref(`blog/${post_id}`).set({
		post_id,
		post_title,
		post_author,
		post_content,
		post_date,
		post_pub_date,
		post_status,
		post_category,
		post_url
	});

export const doAddBlogPost = (
	post_title,
	post_author,
	post_content,
	post_date,
	post_pub_date,
	post_status,
	post_category,
	post_url,
	post_images,
) => {
	return new Promise((resolve, reject) => {
		const newRef = db
			.ref(`blog/`)
			.push();
		db
			.ref(`blog/${newRef.ref.key}`).set({
				post_id: newRef.ref.key,
				post_title,
				post_author,
				post_content,
				post_date,
				post_pub_date,
				post_status,
				post_category,
				post_url,
				post_images
			});
		if (newRef.ref.key) {
			resolve(newRef.ref.key);
		} else {
			reject('error');
		}
	})
}

export const doUpdateBlogPost = (
         post_id,
         post_title,
         post_author,
         post_content,
         post_date,
         post_pub_date,
         post_status,
         post_category,
		 post_url,
		 post_images,
	   ) =>
         db
           .ref(`blog/${post_id}`)
           .set({
             post_id,
             post_title,
             post_author,
             post_content,
             post_date,
             post_pub_date,
             post_status,
			 post_category,
			 post_url,
			post_images,
					 });

export const doUpdateComment = (
	comment_id, blog_id, name, comment, approved, comment_date
				) => {
					return db
							.ref(`comments/${comment_id}`)
							.set({
								blog_id, name, comment, approved, comment_date
							});
				}


export const doCreateSubscriber = (token) => db
	.ref(`subscribers/${token}`)
	.set({ token });


export const onceGetUsers = () =>
	db.ref('users').once('value');

export const onceGetPositions = () =>
	db.ref('positions').once('value');

export const onceGetBlog = () =>
	db.ref('blog').once('value');

export const onDeleteCategory = (id) =>
	db.ref(`categories/${id}`).remove();

export const onDeleteComment = (id) =>
	db.ref(`comments/${id}`).remove();

export const onceGetComments = () =>
	db.ref("comments").once("value");

export const onDeleteBlog = (id) =>
	db.ref(`blog/${id}`).remove();

export const doDeleteImage = (id) =>
	db.ref(`images/${id}`).remove();

export const onceGetCategories = () =>
	db.ref("categories").once("value");

export const onceGetImages = () =>
	db.ref('images').once('value');

export const onceGetSubscribers = () =>
	db.ref('subscribers').once('value');

export const onceGetUser = (id) =>
	db.ref(`users/${id}`).once('value');

export const onceGetBlogById = (id) =>
	db.ref(`blog/${id}`).once('value');

export const onceGetRefunds = () =>
	db.ref('refunds').once('value');

export const onceGetTickets = () =>
	db.ref('tickets').once('value');