import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  faBars,
  faHome,
  faSignOutAlt,
  faMapPin,
  faImages,
  faBlog,
  faList
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auth } from '../firebase';
import * as routes from '../constants/routes';

import SideNav, {
  NavItem,
  NavIcon,
  NavText
} from "@trendmicro/react-sidenav";
import logowhite from "../img/logo-white.png";


class Navigation extends Component {
	constructor(props) {
		super(props);
    this.toggleNav = this.toggleNav.bind(this);
		this.signOut = this.signOut.bind(this);
		this.state = {
      navOpen: false,
      setFixed: false,
      mainNavFixed: false,
		}
		this.defaultState = this.state;
  }

  componentDidMount() {
    window.addEventListener('scroll', _.debounce(() =>{// lodash debounce method.
      let supportPageOffset = window.pageXOffset !== undefined;
      let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
      let scroll = {
        x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
        y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
      };

      if(scroll.y > 70){
          this.setState({setFixed: true});//change the attribute.
      } else {
        this.setState({setFixed: false});
      }

      if(scroll.y >0) {
        this.setState({mainNavFixed: true})
      } else {
        this.setState({mainNavFixed: false})
      }
  }, 0));
  }


  toggleNav = () => {
		this.setState({ navOpen: !this.state.navOpen })
	}

	signOut() {
    auth.doSignOut();
		this.setState(this.defaultState);
	}

	render () {
    const { navCollapsed } = this.state;
    if (this.props.location.pathname === '/sign-in') {
      return (
        <div></div>
      )
    }
    return (
      <div className={this.state.mainNavFixed ? 'mainnav-fixed' : ''}>
        <div className="navbar top-bar">
          <div className="nav-container flex-wrap">
            <Link to={routes.HOME} className="logo">
              <img src={logowhite} alt="Dizzy Di" height="100%" />
            </Link>
          </div>
        </div>

        {this.props.authUser &&
        this.props.location.pathname !== "/" &&
        this.props.location.pathname !== "/tracking" ? (
          <NavigationAuth
            location={this.props.location}
            history={this.props.history}
            onToggleNav={this.toggleNav}
            navOpen={this.state.navOpen}
            authUser={this.props.authUser}
            navCollapsed={navCollapsed}
            signOut={this.signOut}
            fixedClass={this.state.setFixed ? 'sidenav-fixed' : ''}
          />
        ) : (
          <nav className="navbar bottom-bar">
            <div className="nav-container">
              <span className="navbar-toggle" onClick={this.toggleNav}>
                <FontAwesomeIcon icon={faBars} />
              </span>
              <NavigationNonAuth
                navOpen={this.state.navOpen}
                onToggleNav={this.toggleNav}
                navCollapsed={navCollapsed}

              />
            </div>
          </nav>
        )}
      </div>
    );
	}

}

Navigation.contextTypes = {
	authUser: PropTypes.object,
	users: PropTypes.object,
};

const NavigationAuth = ({ signOut, location, history, fixedClass }) => (
  <SideNav
    className={`sidenav-admin ${fixedClass ? 'sidenav-fixed' : ''}`}
    onSelect={selected => {
      if (selected === "logout") {
        return signOut();
      }
      const to = "/" + selected;
      if (location.pathname !== to) {
        history.push(to);
      }
    }}
    onToggle={state =>
      state
        ? document.body.classList.add("menuOpen")
        : document.body.classList.remove("menuOpen")
    }
  >
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="home">
      <NavItem eventKey="home">
        <NavIcon>
          <FontAwesomeIcon icon={faHome} />
        </NavIcon>
        <NavText>Home</NavText>
      </NavItem>
      <NavItem eventKey="admin-blog">
        <NavIcon>
          <FontAwesomeIcon icon={faBlog} />
        </NavIcon>
        <NavText>Blog</NavText>
      </NavItem>
      <NavItem eventKey="admin-tracking">
        <NavIcon>
          <FontAwesomeIcon icon={faMapPin} />
        </NavIcon>
        <NavText>Track</NavText>
      </NavItem>
      <NavItem eventKey="media">
        <NavIcon>
          <FontAwesomeIcon icon={faImages} />
        </NavIcon>
        <NavText>Media</NavText>
      </NavItem>
      <NavItem eventKey="categories">
        <NavIcon>
          <FontAwesomeIcon icon={faList} />
        </NavIcon>
        <NavText>Categories</NavText>
      </NavItem>
      <NavItem eventKey="logout">
        <NavIcon>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </NavIcon>
        <NavText>Logout</NavText>
      </NavItem>
    </SideNav.Nav>
  </SideNav>
);

const NavigationNonAuth = ({ navOpen, navCollapsed, onToggleNav}) => {
	return <React.Fragment>
      <ul className={`main-nav ${navOpen ? "active" : ""}`}>
        <li>
        <Link className="nav-links" to={routes.LANDING} onClick={onToggleNav}>
            HOME
        </Link>
        </li>
        <li>
        <Link className="nav-links" to={routes.TRACKING} onClick={onToggleNav}>
            TRACKING
          </Link>
        </li>
      </ul>
    <Link to="/sign-in" className="login-button" type="button" onClick={onToggleNav}>LOGIN</Link>
    </React.Fragment>;
}

const mapStateToProps = (state) => ({
	authUser: state.session.authUser
});

export default withRouter(connect(mapStateToProps)(Navigation));