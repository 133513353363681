import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import Loader from "react-loader-spinner";
// import withAuthorization from './withAuthorization';
import { onGetCategories, onCreateCategory, deleteCategory } from "../actions/blog";
// import data from '../json/blogposts.json';
// import { db } from "../firebase";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: ""
	};
	this.onSave = this.onSave.bind(this);
  }
  componentDidMount() {
    this.props.onGetCategories();
  }

  onHandleChange(value, key) {
    this.setState({ [key]: value });
  }

  onSave() {
    this.props.onCreateCategory(this.state.category);
  }
  render() {
    const { categories } = this.props;
    const { category } = this.state;

    return (
      <React.Fragment>
          <div className="row">
            <div className="col-xs-12">
              <h1>Categories</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="box-row border-box-row box-filters">
                <ul>
                  {categories &&
                    Object.keys(categories).map((category, i) => {
                      return <li key={i}>{categories[category].category}<small onClick={() => this.props.deleteCategory(category)}>x</small></li>;
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row p-0">
            <div className="col-xs-12 col-md-12">
              <div className="box-row">
                <fieldset className="form-fieldset">
                  <div className="form-element form-input">
                    <input
                      id="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                      className="form-element-field"
                      placeholder="Enter a new category"
                      type="input"
                      value={category}
                      required
                      onChange={evt =>
                        this.onHandleChange(evt.target.value, "category")
                      }
                    />
                    <div className="form-element-bar" />
                    <label
                      className="form-element-label"
                      htmlFor="field-omv6eo-metm0n-5j55wv-w3wbws-6nm2b9"
                    >
                      Add categoy
                    </label>
                  </div>
                </fieldset>
                <div className="form-actions">
                  <button
                    className="form-btn-cancel -nooutline"
                    type="button"
                    onClick={() => this.onSave()}
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
	categories: state.blog.categories,
});

const mapDispatchToProps = {
	onGetCategories,
  onCreateCategory,
  deleteCategory
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(Categories);

