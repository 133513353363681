import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { auth, db } from '../firebase';
import * as routes from '../constants/routes';



const SignUpPage = ({history}) =>
	<div className="inner-bg">
		<div className="container">
			<div className="row">
				<div className="col-sm-12">
					<div className="form-box">
						<SignUpForm history={history}/>
					</div>
				</div>
			</div>
		</div>
	</div>

const INITIAL_STATE = {
	firstName: '',
	lastName: '',
	email: '',
	passwordOne: '',
	passwordTwo: '',
	error: null,
	review: false,
	userId: '',
	loading: false,
	isChecked: false,
};

const byPropKey = (propertyName, value) => () => ({
	[propertyName]: value,
});

class SignUpForm extends Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
		// onStripeUpdate must be bound or else clicking on button will produce error.
		this.onRegister = this.onRegister.bind(this);
		// binding loadStripe as a best practice, not doing so does not seem to cause error.

	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	onRegister(e) {
		const {
			firstName,
			lastName,
			email,
			passwordOne,
		} = this.state;


		auth.doCreateUserWithEmailAndPassword(email, passwordOne)
			.then(authUser => {
				const userId = authUser.uid;
				this.setState({ userId })
				// Create a user in your own accessible Firebase Database too
				db.doCreateUser(userId, firstName, lastName, email)
					.then(() => {
						this.props.history.push(routes.HOME);
					})
					.catch(error => {
						this.setState(byPropKey('error', error));
					});

			})
			.catch(error => {
				this.setState(byPropKey('error', error));
			});
		
		e.preventDefault();
	}

	addTickets() {
		let amount = this.state.tickets
		if (amount >= 10000) {
			amount = 10000
		} else {
			amount = amount+1
		}
		this.setState({tickets: amount})
	}

	toggleChange = () => {
		this.setState({
			isChecked: !this.state.isChecked,
		});
	}

	removeTickets() {
		let amount = this.state.tickets
		if (amount <= 0) {
			amount = 0
		} else {
			amount = amount - 1
		}
		this.setState({ tickets: amount })
	}

	manuallyAddTickets(evt) {
		let amount = parseInt(evt.target.value, 10);
		if (amount > 0 && amount < 10000) {

		} else if (amount <= 0) {
			amount = 0;
		} else if (amount >= 10000) {
			amount = 10000;
		} else {
			amount = 0;
		}
		this.setState({ tickets: amount })
	}

	onAnswerChange(evt) {
		this.setState({ answer: evt.target.value});
	}

	telephoneNumberInsert(event) {
		const value = event.target.value.replace(/\s/g, '');

		this.setState(byPropKey('telephone', value))
	}

	checkNumberValid(number) {
		if (number.length !== 0 && number.length < 11 ) {
			return true;
		}
		return isNaN(number)
	}

	checkEmailValid(email) {
		const re = /\S+@\S+\.\S+/;
		if (email !== '') {
			return !re.test(email);
		}
		return false;
	}

	checkPasswordsMatch(one, two) {
		if (one.length > 0 && two.length > 0) {
			return one !== two;
		}
		return false;
	}

	render() {

		const {
			firstName,
			lastName,
			email, 
			passwordOne,
			passwordTwo,
			error,
			loading,
		} = this.state;

		const isEmailValid = this.checkEmailValid(email);
		const isPasswordMatch = this.checkPasswordsMatch(passwordOne, passwordTwo);

		const isInvalid =
			passwordOne !== passwordTwo ||
			passwordOne === '' ||
			email === '' ||
			firstName === '' ||
			lastName === '' === ''

		
		return (
			<div>
				<form className="form-horizontal">
					<div className="row">
						<div className= "col-lg-6">
							<div className="form-group">
								<label className="control-label col-lg-3" htmlFor="firstName">First name:</label>
								<div className="col-lg-9">
									<input
										className="form-control"
										value={firstName}
										onChange={event => this.setState(byPropKey('firstName', event.target.value))}
										type="text"
										placeholder="First Name"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group">
								<label className="control-label col-lg-3" htmlFor="lastName">Surname:</label>
								<div className="col-lg-9">
									<input
										className="form-control"
										value={lastName}
										onChange={event => this.setState(byPropKey('lastName', event.target.value))}
										type="text"
										placeholder="Last Name"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group">
								<label className="control-label col-lg-3" htmlFor="email">Email:</label>
								<div className="col-lg-9">
									<input
										className="form-control"
										value={email}
										onChange={event => this.setState(byPropKey('email', event.target.value))}
										type="text"
										placeholder="Email Address"
									/>
									{isEmailValid && <div className="errorText">Please enter a valid email address</div>}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group">
								<label className="control-label col-lg-3" htmlFor="pwd">Password:</label>
								<div className="col-lg-9">
									<input
										className="form-control"
										value={passwordOne}
										onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
										type="password"
										placeholder="Password"
									/>
									{isPasswordMatch && <div className="errorText">Your passwords do no match</div>}
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group">
								<label className="control-label col-lg-3" htmlFor="pwd">Confirm Password:</label>
								<div className="col-lg-9">
									<input
										className="form-control"
										value={passwordTwo}
										onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
										type="password"
										placeholder="Confirm Password"
									/>
									{isPasswordMatch && <div className="errorText">Your passwords do no match</div>}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12">
							{error && <div className="errorText text-center">{error.message}</div>}
						</div>
					</div>
						
					<div className="row">
						<div className="col-xs-12 stripe-wrapper">
							{(loading)
								? <p>loading..</p>
								: <button className="btn btn-success btn-block" disabled={isInvalid} onClick={this.onRegister}>Register</button>
							}
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 text-center u-mat-t-large">
							<Link to={routes.SIGN_IN}>
								Have an account? Log In here
							</Link>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(SignUpPage);

export {
	SignUpForm
};