import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Loader from "react-loader-spinner";
import withAuthorization from './withAuthorization';
import { onGetUserData } from '../actions/session';
import Categories from './Categories';
import Comments from './Comments';
// import data from '../json/blogposts.json';
// import { db } from "../firebase";

class HomePage extends Component {
  componentDidMount() {
    this.props.onGetUserData(this.props.session.authUser.uid);

    // data.filter(item => item.post_type.__cdata === "post").map(blogPost => {
    //   const arr = blogPost.link.split('/');
    //   // return db.doAddBlogPostLegacy(blogPost.post_id.__text,
    //   //   blogPost.title,
    //   //   blogPost.creator.__cdata === "keithvernonjones" ? "Keith Jones" : "Diane Jones",
    //   //   blogPost.encoded[0].__cdata,
    //   //   blogPost.post_date.__cdata,
    //   //   blogPost.pubDate,
    //   //   blogPost.status.__cdata,
    //   //   blogPost.category.__cdata,
    //   //   arr[arr.length-2])
    //   }
    //   );

    }




  render() {
    const { session } = this.props;
    return <div className="admin-panel">
              <div className="leftFiller" />
              <div className="container-admin">
                <div className="row">
                  <div className="col-xs-12">
                    {!session.loading ? <div className="section">
                        <h1> Hi {session.user.firstName}</h1>
                      </div> : <div className="loading">
                  <Loader type="Grid" color="#005698" height={60} width={60} />
                      </div>}
                  </div>
              </div>
              <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <Categories />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Comments />
                  </div>
              </div>
            </div>
          </div>;
  }
}


const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = {
  onGetUserData
};

const authCondition = (authUser) => !!authUser;

export default compose(
	withAuthorization(authCondition),
	connect(mapStateToProps, mapDispatchToProps)
)(HomePage);

