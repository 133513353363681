import {
	FETCH_USER_DATA,
	FETCH_USER_DATA_SUCCESS,
	FETCH_USER_DATA_ERROR
} from '../constants';

import { db } from "../firebase";

export const fetchUserDataBegin = payload => ({ type: FETCH_USER_DATA });
export const fetchUserDataSuccess = payload => ({ type: FETCH_USER_DATA_SUCCESS, payload });
export const fetchUserDataError = payload => ({ type: FETCH_USER_DATA_ERROR, payload });

export function onGetUserData(userId) {
	return dispatch => {
		dispatch(fetchUserDataBegin());
			return db
		.onceGetUser(userId)
		.then(snapshot => {
			dispatch(fetchUserDataSuccess(snapshot.val()));
		})
		.catch(error => {
			dispatch(fetchUserDataError(error));
		});
	};
}