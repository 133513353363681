import * as firebase from 'firebase';

const config = {
  apiKey: "AIzaSyD8XtVbpysymbvsoegipQ6zlwkekaiCF6U",
  authDomain: "dizzydi-4f0dc.firebaseapp.com",
  databaseURL: "https://dizzydi-4f0dc.firebaseio.com",
  projectId: "dizzydi-4f0dc",
  storageBucket: "dizzydi-4f0dc.appspot.com",
  messagingSenderId: "972711327624"
  };

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}
const db = firebase.database();
const auth = firebase.auth();
const messaging = firebase.messaging();
const storage = firebase.storage();

export { db, auth, messaging, storage };