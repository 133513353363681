import React, {Component} from 'react';
import preval from 'preval.macro';
import {
  withRouter
} from 'react-router-dom';
import { unregister } from '../registerServiceWorker';
import { loadReCaptcha } from 'react-recaptcha-v3'
import GoogleApiComponent from "./Maps/GoogleApiComponent";

import Navigation from './Navigation';
import AppRouter from "./AppRouter";

import withAuthentication from './withAuthentication';

class App extends Component {

  componentDidMount() {
    loadReCaptcha("6LfiS6YUAAAAAArqcuqCc1E2ac4iknUf1-aS0nhC");
  }

  render(){
    return (
      <div className="app__wrapper" databuilddate={`${preval`module.exports = new Date().toLocaleString();`}.`}>
        <Navigation />
        <AppRouter/>
      </div>
    )
  }
}


unregister()
export default GoogleApiComponent({
  apiKey: 'AIzaSyA_W66BlnQeNx4HRI9RneoSAON4G6tBlLo'
})(withRouter(withAuthentication(App)));