import {
	FETCH_POSITIONS,
	FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_ERROR,
  FETCH_IMAGES,
  FETCH_IMAGES_SUCCESS,
  FETCH_IMAGES_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  REMOVE_IMAGE,
  UPDATE_POSITION,
  UPDATE_POSITION_SUCCESS,
} from '../constants';

import { db } from "../firebase";

import { storage } from "../firebase/firebase";

export const fetchPositions = payload => ({ type: FETCH_POSITIONS });
export const fetchPositionsSuccess = payload => ({ type: FETCH_POSITIONS_SUCCESS, payload });
export const fetchPositionsError = payload => ({ type: FETCH_POSITIONS_ERROR, payload });

export const updatePosition = payload => ({ type: UPDATE_POSITION });
export const updatePositionSuccess = payload => ({ type: UPDATE_POSITION_SUCCESS, payload });

export const fetchImages = payload => ({ type: FETCH_IMAGES });
export const fetchImagesSuccess = payload => ({ type: FETCH_IMAGES_SUCCESS, payload });
export const fetchImagesError = payload => ({ type: FETCH_IMAGES_ERROR, payload });

export const uploadImageBegin = payload => ({ type: UPLOAD_IMAGE });
export const uploadImageSuccess = payload => ({ type: UPLOAD_IMAGE_SUCCESS, payload });
export const uploadImageError = payload => ({ type: UPLOAD_IMAGE_ERROR, payload });

export const removeImageBegin = payload => ({ type: REMOVE_IMAGE });

function reverseObject(object) {
  var newObject = {};
  var keys = [];

  for (var key in object) {
      keys.push(key);
  }

  for (var i = keys.length - 1; i >= 0; i--) {
      var value = object[keys[i]];
      newObject[keys[i]]= value;
  }

  return newObject;
}

export function onGetPositions() {
  return dispatch => {
    dispatch(fetchPositions());
    return db
      .onceGetPositions()
      .then(snapshot => {
        dispatch(fetchPositionsSuccess(snapshot.val()));
      })
      .catch(error => {
        dispatch(fetchPositionsError(error));
      });
  };
}

export function onUpdatePosition(id, blog_id) {
  return dispatch => {
    dispatch(updatePosition());
    db.doUpdatePosition(id,blog_id);
    return dispatch(onGetPositions());
  };
}

export function onGetImages() {
  return dispatch => {
    dispatch(fetchImages());
    return db
      .onceGetImages()
      .then(snapshot => {
        dispatch(fetchImagesSuccess(reverseObject(snapshot.val())));
      })
      .catch(error => {
        dispatch(fetchImagesError(error));
      });
  };
}

export function uploadImage(filename) {
  return dispatch => {
    dispatch(uploadImageBegin());
    return storage
      .ref("")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        db.doCreateImage(url, filename)
        db
          .onceGetImages()
          .then(snapshot => {
            dispatch(fetchImagesSuccess(reverseObject(snapshot.val())));
          })
        return setTimeout(() => {
          db
          .onceGetImages()
          .then(snapshot => {
            dispatch(fetchImagesSuccess(reverseObject(snapshot.val())));
          });
        },10000)
      })
      .catch(error => {
        dispatch(uploadImageError(error));
      });
  };
}

export function onRemoveImage(id, filename) {
  return dispatch => {
    dispatch(removeImageBegin());
    storage
      .ref("")
      .child(filename)
      .delete();
      db.doDeleteImage(id)
      return db
        .onceGetImages()
        .then(snapshot => {
          dispatch(fetchImagesSuccess(reverseObject(snapshot.val())));
        })
  };
}



