import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DateRange } from 'react-date-range';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import Select from 'react-select';
import Polyline from "./Maps/Polyline";
import moment from 'moment';
import Marker from "./Maps/Marker";
import ClusterMarker from './Maps/ClusterMarker';
import { db } from "../firebase";
import { onGetPositions, onUpdatePosition } from '../actions/tracking';
import { K_CIRCLE_SIZE, K_STICK_SIZE } from "./Maps/IconStuff";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";

import { onGetBlog } from "../actions/blog";

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto'
	}
};

class AddJob extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      positions: [],
      originalRows: [],
      distance: "",
      clusters: [],
      columns: [],
      rows: [],
      mindate: "",
      maxdate: "",
      rangePicker: {},
      markerWidth: 30,
      markerHeight: 30,
      predefined: {},
      format: 'dddd, D MMMM YYYY',
      center: { lat: 50.859740, lng: -1.310538 },
      zoom: 11,
      mapLoaded: false,
      errorMsg: false,
      markers: [],
      currentLocation: '',
      timeformat: 'No tracking data for today',
      tooltipActive:{},
      blog: {},
      blogOptions: {},
      selectedBlog: null,
      modalIsOpen: false,
      blogFilter: false,
      maps: null,
      map: null,
      reset: false
    };
    this.state = {
      positions: [],
      originalRows: [],
      distance: "",
      clusters: [],
      columns: [],
      rows: [],
      mindate: "",
      maxdate: "",
      rangePicker: {},
      markerWidth: 30,
      markerHeight: 30,
      predefined: {},
      format: 'dddd, D MMMM YYYY',
      center: { lat: 50.859740, lng: -1.310538 },
      zoom: 11,
      mapLoaded: false,
      errorMsg: false,
      markers: [],
      currentLocation: '',
      timeformat: 'No tracking data for today',
      tooltipActive:{},
      blog: {},
      blogOptions: {},
      selectedBlog: null,
      modalIsOpen: false,
      blogFilter: false,
      maps: null,
      map: null,
      reset: false
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onHandleSelectChange = this.onHandleSelectChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.props.onGetPositions();
    this.props.onGetBlog();
  }


  componentWillUnmount() {
    this.setState({
      positions: [],
      originalRows: [],
      distance: "",
      clusters: [],
      columns: [],
      rows: [],
      mindate: "",
      maxdate: "",
      rangePicker: {},
      markerWidth: 30,
      markerHeight: 30,
      predefined: {},
      format: 'dddd, D MMMM YYYY',
      center: { lat: 50.859740, lng: -1.310538 },
      zoom: 11,
      mapLoaded: false,
      errorMsg: false,
      markers: [],
      currentLocation: '',
      timeformat: 'No tracking data for today',
      tooltipActive:{},
      blog: {},
      blogOptions: {},
      selectedBlog: null,
      modalIsOpen: false,
      blogFilter: false,
      maps: null,
      map: null,
      reset: true
    });
  }



  componentWillReceiveProps(nextProps) {
    const positions = nextProps.tracking.data;
    const blog = nextProps.blog;

    if (this.props.tracking.data ) {
      if (Object.keys(positions).length !== Object.keys(this.props.tracking.data).length ) {
        this.getPositions(positions);
      }
      const columns = this.columnCreate(positions);
      // this.getLastKnownLocation();
      const originalRows = this.rowsCreate(positions)
      const rows = originalRows.slice(Math.max(originalRows.length - 20, 0));
      const markers = originalRows.slice(0)
      const allDates = this.reduceDates(positions)
      const maxdate = moment(allDates[1]).format('DD/MM/YYYY');
      const mindate = moment(allDates[0]).format('DD/MM/YYYY');
      this.setState({ positions, rows, originalRows, columns, maxdate, mindate, markers })

      if (this.state.map) {
        var bounds = new this.state.maps.LatLngBounds();
        rows.map(row => {
          var latLng = new this.state.maps.LatLng(row.lat, row.lng);
          return bounds.extend(latLng);
        });
        this.state.map.fitBounds(bounds);
      }
    }
    if (Object.keys(blog).length > 0 ) {
      const newBlog = this.sortByDate(nextProps.blog, false, 'post_date');
      this.setState({
        blog,
        blogOptions: Object.keys(newBlog).map(item => Object.assign({}, {
          label: `${this.convertDate(newBlog[item].post_date)} - ${newBlog[item].post_title}`,
          value: newBlog[item].post_id
      }))})
    }
  }


  refreshPoints(positions) {
    this.getPositions(positions);
  }

  afterOpenModal() {
		// references are now sync'd and can be accessed.
		// this.subtitle.style.color = '#f00';
  }

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

  convertDate(date) {
		const year = date.substring(0, 4);
		const month = date.substring(5,7);
		const day = date.substring(8, 10);
		return `${day}-${month}-${year}`;
  }

  sortByDate(data, sortReverse, key) {
    return Object.keys(data).sort((a, b) => {
      const bDateOnly = data[b][key].substring(0,10);
      const aDateOnly = data[a][key].substring(0, 10);
      const bTimeOnly = data[b][key].substring(11, 16);
      const aTimeOnly = data[a][key].substring(11, 16);
      if(sortReverse) {
        return (
          aDateOnly.localeCompare(bDateOnly) ||
          aTimeOnly.localeCompare(bTimeOnly)
        );
      } else {
        return (
          bDateOnly.localeCompare(aDateOnly) ||
          bTimeOnly.localeCompare(aTimeOnly)
        );
      }
    }).map(item => data[item]);
  }

  reduceDates(data) {
    return Object.keys(data).reduce(
      (accumulator, currentValue) => {
        return [
          Math.min(
            parseInt(currentValue.datetimemilli, 10),
            accumulator[0]
          ),
          Math.max(parseInt(currentValue.datetimemilli, 10), accumulator[1])
        ];
      },
      [Number.MAX_VALUE, Number.MIN_VALUE]
    );


  }

  createClusters = props => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this
          .getClusters(props)
          .map(({ wx, wy, numPoints, points }) => {
            if (points[0].id === "56303095") {
            }
            return ({ lat: wy, lng: wx, numPoints, id: `${numPoints}_${points[0].id}`, points })
          })
        : []
    });
  };

  columnCreate(positions) {
    return [
      { key: 'id', name: 'ID' },
      { key: 'speed', name: 'Speed Over Ground' },
      { key: 'longitude', name: 'Longitude' },
      { key: 'latitude', name: 'Latitude' },
      { key: 'date', name: 'Date' },
      { key: 'blog_id', name: 'Blog' }
    ]
  }

  rowsCreate(positions) {
    let rows = [];
    Object.keys(positions).map(function(row, index) {
      return rows.push({
        id: positions[row].id,
        speed: `${positions[row].sogKnots} knots`,
        lat: Number(positions[row].latitude),
        lng: Number(positions[row].longitude),
        date: `${moment(parseInt(positions[row].gpsAtMillis, 10)).format(
          "DD/MM/YYYY"
        )}`,
        time: `${moment(parseInt(positions[row].gpsAtMillis, 10)).format("HH:MM:ss")}`,
        cog: `${positions[row].cog}`,
        blog_id: positions[row].blog_id
      });
    });

    return rows
  }

  distanceToMouse = (markerPos, mousePos, markerProps) => {
    const x = markerPos.x;
    // because of marker non symmetric, we transform it central point to measure
    // distance from marker circle center you can change distance function to any
    // other distance measure
    const y = markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2;

    // and i want that hover probability on markers with text === 'A' be greater
    // than others so i tweak distance function (for example it's more likely to me
    // that user click on 'A' marker) another way is to decrease distance for 'A'
    // marker this is really visible on small zoom values or if there are a lot of
    // markers on the map
    const distanceKoef = markerProps.text !== 'A'
      ? 1.5
      : 1;

    // it's just a simple example, you can tweak distance function as you wish
    return distanceKoef * Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
  }

  rowGetter(i) {
    return this.state.rows[i];
  }

  getClusters = props => {
    const clusters = supercluster(this.state.rows, {
      minZoom: 0,
      maxZoom: 16,
      radius: 30
    });

    return clusters(this.state.mapOptions);
  };
  handleActiveClass(evt) {
    for (var item of document.querySelectorAll('.collapse--button')) {
      item.classList.remove('selected');
    }
    evt.target.classList.add('selected');
    const arr = document.querySelectorAll('.rdr-PredefinedRangesItem')
    for (var i = 0; i < arr.length; i++) {

      if (arr[i].classList.contains('rdr-PredefinedRangesItemActive')) {
        arr[i].classList.add("rdr-PredefinedRangesItemActiveCustom");
      } else {
        arr[i].classList.remove("rdr-PredefinedRangesItemActiveCustom");
      }
    }

    var classname = document.getElementsByClassName("rdr-PredefinedRangesItem");
    for (let i = 0; i < classname.length; i++) {
      classname[i].addEventListener('click', function (el) {
        var newarr = document.querySelectorAll('.rdr-Calendar');
        for (let j = 0; j < newarr.length; j++) {
          if (newarr[j].classList.contains('show')) {
            newarr[j].classList.remove("show");
          }
        }
        const arr = document.querySelectorAll('.collapse--button');
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].classList.contains('rdr-PredefinedRangesItemActive')) {
            arr[i].classList.remove("rdr-PredefinedRangesItemActive");
          }
        }
        el.target.classList.remove('rdr-PredefinedRangesItemActiveCustom')
      }, false);
    }
  }

  calculateDistance(pointA, pointB) {
    // http://www.movable-type.co.uk/scripts/latlong.html
    const lat1 = pointA.lat();
    const lon1 = pointA.lng();

    const lat2 = pointB.lat();
    const lon2 = pointB.lng();

    const R = 6371e3; // earth radius in meters
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2)) +
      ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance; // in meters
  }

  calcPathLength(cPath) {
    var maps = this.refs.googleMap.maps_;
    var total = 0;
    var sum = 0;
    var speedArray = cPath.filter(item => parseInt(item.speed, 10) > 0)
    if (maps) {
      for (var i = 0; i < cPath.length - 1; i++) {
        var pos1 = new maps.LatLng(cPath[i].lat, cPath[i].lng);
        var pos2 = new maps.LatLng(cPath[i + 1].lat, cPath[i + 1].lng);
        if (parseInt(cPath[i].speed, 10) > 0) {
          sum += parseInt(cPath[i].speed, 10);
        }
        total += this.calculateDistance(pos1, pos2);
      };
      const distance = `Distance travelled -  ${(total / 1609.34).toFixed(1)} miles at an average of ${isNaN((sum / speedArray.length).toFixed(1)) ? 0 : (sum / speedArray.length).toFixed(1)} knots`;
      this.setState({ distance });
    }
  };

  // getLastKnownLocation() {
  //   console.log(this.state.originalRows[1])
  //   const firstStatePosition = this.state.originalRows[0] || { latitude: 50.859740, longitude: -1.310538 };

  //   const targetUrl = `https://maps.google.com/maps/api/geocode/json?latlng=${firstStatePosition.latitude},${firstStatePosition.longitude}&sensor=false`;
  //   let position = `latlng=${firstStatePosition.latitude},${firstStatePosition.longitude}`;
  //   fetch(targetUrl)
  //     .then(blob => blob.json())
  //     .then(jsonData => {
  //       position = jsonData.results[0].formatted_address;
  //       this.setState({ currentLocation: position })
  //     })
  //     .catch(e => {
  //       position = e;
  //     });
  //   return position;


  // }


  rowFilter(payload) {
    var dateArray = [];
    var currentDate = moment(payload.startDate);
    var endDate = moment(payload.endDate);
    while (currentDate <= endDate) {
      dateArray.push(moment(currentDate).format('DD/MM/YYYY'))
      currentDate = moment(currentDate).add(1, 'days');
    }
    let rows;
    rows = this.state.originalRows.filter(item => dateArray.indexOf(item.date) > -1 && item);

    if (rows.length === 0) {

      rows = [{
        id: 1,
        speed: 0,
        lat: 50.859740,
        lng: -1.310538,
        date: `${moment(currentDate).format('DD/MM/YYYY')}`,
        time: `${moment(currentDate).format('HH:MM:ss')}`,
        cog: 0,
        blog_id: false
      }, {
        id: 1,
        speed: 0,
        lat: 50.859740,
        lng: -1.310538,
        date: `${moment(currentDate).format('DD/MM/YYYY')}`,
        time: `${moment(currentDate).format('HH:MM:ss')}`,
        cog: 0,
        blog_id: false
      }]
    }
    //

    if (this.state.map) {
      var bounds = new this.state.maps.LatLngBounds();
      rows.map((row) => {
        var latLng = new this.state.maps.LatLng(row.lat, row.lng);
        return bounds.extend(latLng);
      });
     this.state.map.fitBounds(bounds);

      if (rows.length === 2) {
        this.state.map.setZoom(13);
        this.setState({ errorMsg: true })
      } else {
        this.setState({ errorMsg: false })
      }
      this.calcPathLength(rows);
      this.setState({ rows, markers: rows }, () => {
        this.createClusters(this.props);
      });
    }
  }

  daysBetween(date1, date2, endDate) {
    let string_format = '';

    // Convert both dates to milliseconds
    var date1_ms = date1.valueOf()
    var date2_ms = date2.valueOf()

    // Calculate the difference in milliseconds
    var difference_ms = (date2_ms - date1_ms) / 1000 / 86400;

    const calc = Math.round(difference_ms - 0.5);
    // Convert back to days and return
    if (calc === 1) {
      string_format = "No tracking data for yesterday";
    } else if (calc === 7) {
      string_format = "No tracking data for the last 7 days";
    } else if (calc === 30) {
      string_format = "No tracking data for the last 30 days";
    } else if (calc > 0) {
      const msg = date1.format('DD/MM/YY') === endDate.format('DD/MM/YY') ? `for ${date1.format('DD/MM/YYYY')}` : `between ${date1.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`
      string_format = `No tracking data ${msg}`;
    } else {
      string_format = "No tracking data for today";
    }
    this.setState({ timeformat: string_format })
    return string_format;
  }

  handleChange(which, payload) {
    const startDate = moment(payload.startDate);
    const endDate = moment(payload.endDate);
    this.daysBetween(startDate, moment(), endDate);
    this.setState({
      [which]: payload
    });

    this.rowFilter(payload)
  }

  handleCalendarShow(evt) {
    this.handleActiveClass(evt);
    evt.target.classList.add('rdr-PredefinedRangesItemActive')
    var arr = document.querySelectorAll('.rdr-Calendar');
    for (var i = 0; i < arr.length; i++) {

      if (arr[i].classList.contains('show')) {
        arr[i].classList.remove("show");
      } else {
        arr[i].classList.add("show");
      }

    }
  }

  showAll(evt) {
    this.setState({blogFilter: false})
    this.handleActiveClass(evt);
    const rows = this.state.originalRows;
    this.calcPathLength(rows)
    this.setState({ rows, errorMsg: false, markers: rows });
    if (this.state.map) {
      var bounds = new this.state.maps.LatLngBounds();
      rows.map((row) => {
        var latLng = new this.state.maps.LatLng(row.lat, row.lng);
        return bounds.extend(latLng);
      });
      this.state.map.fitBounds(bounds);
    }

  }

  filterYear(evt, year) {
    this.setState({blogFilter: false})
    this.handleActiveClass(evt);
    let rows = this.state.originalRows.filter(position => {
      return position.date.substring(6,10) === year;
    })

    if (rows.length === 0) {
      // rows = [{
      //   id: 1,
      //   speed: 0,
      //   lat: 50.859740,
      //   lng: -1.310538,
      //   date: `${moment().format('DD/MM/YYYY')}`,
      //   time: `${moment().format('HH:MM:ss')}`,
      //   cog: 0,
      //   blog_id: false
      // }]
    }

    this.calcPathLength(rows);
    this.setState({ rows, errorMsg: false, markers: rows });
    if (this.state.map) {
      var bounds = new this.state.maps.LatLngBounds();
      rows.map(row => {
        var latLng = new this.state.maps.LatLng(row.lat, row.lng);
        return bounds.extend(latLng);
      });
      this.state.map.fitBounds(bounds);
    }
  }

  showRecent(evt) {
    this.setState({blogFilter: false})
    this.handleActiveClass(evt);
    const rows = this.state.originalRows.slice(Math.max(this.state.originalRows.length - 20, 0));

    this.calcPathLength(rows);
    this.setState({ rows, errorMsg: false, markers: rows });
    if (this.state.map) {
      var bounds = new this.state.maps.LatLngBounds();
      rows.map(row => {
        var latLng = new this.state.maps.LatLng(row.lat, row.lng);
        return bounds.extend(latLng);
      });
      this.state.map.fitBounds(bounds);
    }
  }

  showBlogPoints(evt) {
    this.setState({blogFilter: true})
    this.handleActiveClass(evt);
    const rows = this.state.originalRows.filter(position => position.blog_id && position.blog_id.value )

    this.calcPathLength(rows);
    this.setState({ rows, errorMsg: false, markers: rows });

    if (this.state.map) {
      var bounds = new this.state.maps.LatLngBounds();
      rows.map(row => {
        var latLng = new this.state.maps.LatLng(row.lat, row.lng);
        return bounds.extend(latLng);
      });
      this.state.map.fitBounds(bounds);
    }
  }

  onHandleSelectChange(val) {
    this.setState({selectedBlog: val})
  }

  openModal() {
		this.setState({ modalIsOpen: true });
	}

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState({
      mapOptions: {
        center,
        zoom,
        bounds
      }
    }, () => {
      this.createClusters(this.props);
    });
  };

  async getPositions(existingPositions) {
    const proxyUrl = "https://dizzy-di.herokuapp.com/";
    const targetUrl = "https://yb.tl/API3/Race/dizzydi/GetPositions?n=5000";
    const self = this;
    return fetch(proxyUrl + targetUrl)
      .then(blob => blob.json())
      .then(jsonData => {
        const positions = jsonData.teams[0].positions;
        const existingArray = Object.keys(existingPositions).map(id => parseInt(existingPositions[id].id, 10));
        return positions.map(position => {
          if (existingArray.indexOf(position.id) === -1) {
            return db.doAddPosition(
              position.id,
              position.altitude,
              position.battery,
              position.cog,
              position.gpsAt,
              position.gpsAtMillis,
              position.latitude,
              position.longitude,
              position.sogKmph,
              position.sogKnots,
              position.txAt,
              position.type
            ).catch(error => {
                return error
              });
            }
            return null;
        });
      }).then(() => self.props.onGetPositions())
      .catch(e => {
        return e;
      });
  }

  onHandleChange(key, value) {
    this.setState({ [key]: value });
  }

  onChange(value) {
    this.setState({ jobDescription: value });
  }

  formatDate(currentdate) {
    return (
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds()
    );
  }
  onMarkerClick(item) {
    this.setState({tooltipActive: this.state.tooltipActive.id === item.id ? {}  : item, selectedBlog: item.blog_id && item.blog_id.value ? item.blog_id : null})
  }

  render() {
    return (
      <div className="admin-panel justify-center">
        <div>
          {/*this.state.errorMsg ?
            <div className="errorMsg">
              <div className="background"></div>
              <div className="text">{this.state.timeformat}<p>Last known location: {this.state.currentLocation}</p></div>
            </div>
          : null*/}
          {this.state.distance ? (
            <div className="distance">{this.state.distance}</div>
          ) : null}
          <div className={`calendar--container ${this.props.spectator ? 'spectator' : ''}`}>
            <button
              className="collapse--button"
              onClick={evt => this.handleCalendarShow(evt)}
            >
              Calendar
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.refreshPoints(this.props.positions)}
            >
              Refresh
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.showAll(evt)}
            >
              All
            </button>
            <button
              className="collapse--button selected"
              onClick={evt => this.showRecent(evt)}
            >
              Last Twenty
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.showBlogPoints(evt)}
            >
              Blog Map
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.filterYear(evt, '2017')}
            >
              2017
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.filterYear(evt, '2018')}
            >
              2018
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.filterYear(evt, '2019')}
            >
              2019
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.filterYear(evt, '2020')}
            >
              2020
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.filterYear(evt, '2021')}
            >
              2021
            </button>
            <button
              className="collapse--button"
              onClick={evt => this.filterYear(evt, '2022')}
            >
              2022
            </button>
            <DateRange
              format="DD/MM/YYYY"
              minDate={this.state.mindate}
              maxDate={moment().format("DD/MM/YYYY")}
              linkedCalendars={true}
              onChange={this.handleChange.bind(this, "predefined")}
            />
          </div>
          {Object.keys(this.state.positions).length > 0 &&
          <div className="mapContainer">
            <GoogleMapReact
              onGoogleApiLoaded={({ map, maps }) => {
                this.setState({ map: map, maps: maps, mapLoaded: true });
              }}
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
              minZoom={4}
              onChange={this.handleMapChange}
              yesIWantToUseGoogleMapApiInternals={true}
              distanceToMouse={this.distanceToMouse}
              ref="googleMap"
            >
              {!this.state.errorMsg &&
                this.state.rows &&
                this.state.clusters.map((item, i) => {
                  if (item.numPoints === 1) {
                    return (
                      <Marker
                        key={i}
                        lat={item.lat}
                        lng={item.lng}
                        text={""}
                        zIndex={2}
                        item={item}
                        point={this.state.positions[item.points[0]]}
                        onMarkerClick={this.onMarkerClick}
                        hasBlog={item.points.filter(item => item.blog_id).length > 0}

                      />
                    );
                  }

                  return (
                    <ClusterMarker
                      key={i}
                      lat={item.lat}
                      lng={item.lng}
                      text={item.points.length}
                      zIndex={2} /* Kreyser Avrora */
                      hasBlog={item.points.filter(item => item.blog_id).length > 0}
                    />
                  );
                })}
            </GoogleMapReact>
            {this.state.mapLoaded && !this.state.blogFilter && (
              <Polyline
                map={this.state.map}
                maps={this.state.maps}
                points={this.state.rows}
                destination={{ lat: 50.85974, lng: -1.310538 }}
              />
            )}
          </div>
            }
        </div>
        {Object.keys(this.state.tooltipActive).length > 0 &&

          <div className={`tooltip-map ${this.state.tooltipActive.blog_id && this.state.tooltipActive.blog_id.value && this.state.tooltipActive.blog_id.value !== '-1' ? 'tooltip-map__large' : ''}`}>
            <button className="close-btn" onClick={() => this.setState({tooltipActive: {}})}>x</button>
            <div className="row">

              <div className={`${this.state.tooltipActive.blog_id && this.state.tooltipActive.blog_id.value && this.state.tooltipActive.blog_id.value !== '-1' ? 'col-xs-12' : 'col-xs-12'}`}>
                <table className="">
                    <tbody>
                        <tr>
                            <td>Date</td>
                            <td>{this.state.tooltipActive.date} at {this.state.tooltipActive.time}</td>
                            </tr>
                        <tr>
                            <td>Speed</td>
                            <td>{this.state.tooltipActive.speed}</td>
                        </tr>
                        <tr>
                            <td>Course</td>
                            <td>{this.state.tooltipActive.cog}</td>
                        </tr>
                    </tbody>
                </table>
                {!this.props.spectator && this.props.authUser && this.props.authUser.email &&
                  <React.Fragment>
                    <Select
                      value={this.state.selectedBlog}
                      // defaultInputValue="Select Blog..."
                      isClearable
                      name="blogCategory"
                      onChange={this.onHandleSelectChange}
                      options={this.state.blogOptions}
                    />
                    <button
                      className="form-btn" style={{width: "100%", marginTop: '5px'}}
                      onClick={() => {

                        return this.props.onUpdatePosition(this.state.tooltipActive.id, this.state.selectedBlog)
                      }}
                      >Attach to point</button>
                  </React.Fragment>

                }
                {this.state.selectedBlog && this.state.selectedBlog.value && this.state.selectedBlog.value !== '-1' &&
                  <div>
                  {this.state.blog[this.state.selectedBlog.value]
                    .post_images && (
                    <ImageGallery
                    showFullscreenButton={false}
                        showPlayButton={false}
                      items={this.state.blog[
                        this.state.selectedBlog.value
                      ].post_images.map(image =>
                        Object.assign(
                          {},
                          {
                            original: image,
                            thumbnail: image
                          }
                        )
                      )}
                      showThumbnails={false}
                    />
                  )}
                  </div>
                }
                {this.props.spectator && this.state.selectedBlog && this.state.selectedBlogvalue &&
                  <button
                      className="form-btn" style={{width: "100%", marginTop: '5px'}}
                      onClick={() => this.openModal()}
                  >View blog</button>
                }
              </div>
              </div>
              {this.state.selectedBlog && this.state.selectedBlog.value &&
                <Modal
                  isOpen={this.state.modalIsOpen}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Blog"
                  ariaHideApp={false}
                >
                <button className="close" onClick={this.closeModal}>close</button>
                <div className="box-row">
                  <div className="row">
                  <div className="col-xs-12 col-lg-3">
                    {this.state.blog[this.state.selectedBlog.value]
                      .post_images && (
                      <ImageGallery
                        showFullscreenButton={false}
                        showPlayButton={false}
                        items={this.state.blog[
                          this.state.selectedBlog.value
                        ].post_images.map(image =>
                          Object.assign(
                            {},
                            {
                              original: image,
                              thumbnail: image
                            }
                          )
                        )}
                        // showThumbnails={false}
                      />
                    )}
                    </div>
                <div className="col-xs-12 col-lg-9">
                  <h3 className="post-title">
                    {
                      this.state.blog[this.state.selectedBlog.value]
                        .post_title
                    }

                    <small className="post-date">
                      {`${this.convertDate(
                        this.state.blog[this.state.selectedBlog.value]
                          .post_date
                      )} ${this.state.blog[this.state.selectedBlog.value].post_date.substring(11, 16)}`}
                    </small>
                  </h3>
                  <h2>{
                    this.state.blog[this.state.selectedBlog.value]
                      .post_category
                  }</h2>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.blog[
                        this.state.selectedBlog.value
                      ].post_content
                    }}
                  />
                </div>
                  </div>
                  </div>
                </Modal>
                  }
          </div>
        }
      </div>
    );
  }
}


const mapStateToProps = state => ({
  session: state.session,
  tracking: state.tracking,
  blog: state.blog.data,
  authUser: state.session.authUser
});

const mapDispatchToProps = {
  onGetPositions,
  onGetBlog,
  onUpdatePosition
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AddJob);