export const FETCH_POSITIONS = "FETCH_POSITIONS";
export const FETCH_POSITIONS_SUCCESS = "FETCH_POSITIONS_SUCCESS";
export const FETCH_POSITIONS_ERROR = "FETCH_POSITIONS_ERROR";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_ERROR = "FETCH_USER_DATA_ERROR";
export const FETCH_BLOG = "FETCH_BLOG";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_ERROR = "FETCH_BLOG_ERROR";
export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_ERROR = "FETCH_COMMENTS_ERROR";

export const FETCH_IMAGES = "FETCH_IMAGES";
export const FETCH_IMAGES_SUCCESS = "FETCH_IMAGES_SUCCESS";
export const FETCH_IMAGES_ERROR = "FETCH_IMAGES_ERROR";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";
export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS";
export const DELETE_CATEGORIES_ERROR = "DELETE_CATEGORIES_ERROR";

export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR";

export const REMOVE_IMAGE = "REMOVE_IMAGE";

export const UPDATE_POSITION = "UPDATE_POSITION";
export const UPDATE_POSITION_SUCCESS = "UPDATE_POSITION_SUCCESS";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";