import {
	FETCH_USER_DATA,
	FETCH_USER_DATA_SUCCESS,
	FETCH_USER_DATA_ERROR
} from '../constants';

const INITIAL_STATE = {
	loading: false,
	authUser: null,
	user: {},
	error: null
};

const applySetAuthUser = (state, action) => ({
	...state,
	authUser: action.authUser
});

function sessionReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
    case "AUTH_USER_SET": {
      return applySetAuthUser(state, action);
    }
    case FETCH_USER_DATA: {
      return { ...state, loading: true, user: action.payload };
    }
    case FETCH_USER_DATA_SUCCESS: {
      return { ...state, loading: false, user: action.payload };
    }
    case FETCH_USER_DATA_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    default:
      return state;
  }
}

export default sessionReducer;