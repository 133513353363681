const K_CIRCLE_SIZE = 30;
const K_STICK_SIZE = 10;
const K_STICK_WIDTH = 1;

const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates it's on
    // you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_CIRCLE_SIZE,
    height: K_CIRCLE_SIZE + K_STICK_SIZE,
    left: -K_CIRCLE_SIZE / 2,
    top: -(K_CIRCLE_SIZE + K_STICK_SIZE)
};

const greatPlaceCircleStyle = {
    position: 'absolute',
    left: "7.5px",
    top: "-15px",
    width: K_CIRCLE_SIZE,
    height: K_CIRCLE_SIZE,
    cursor: 'pointer',
};

const greatPlaceCircleStyleHover = {
    ...greatPlaceCircleStyle,
};

const greatPlaceStickStyleShadow = {
    position: 'absolute',
    left: "7.5px",
    top: "-15px",
    width: K_STICK_WIDTH,
    height: K_STICK_SIZE,
    backgroundColor: '#f44336',
};

const greatPlaceStickStyle = {
    position: 'absolute',
    left: K_CIRCLE_SIZE / 2 - K_STICK_WIDTH / 2,
    top: K_CIRCLE_SIZE,
    width: K_STICK_WIDTH,
    height: K_STICK_SIZE,
    backgroundColor: '#f44336'
};

const greatPlaceStickStyleHover = {
    ...greatPlaceStickStyle,
    backgroundColor: '#3f51b5'
};

export {
    greatPlaceStyle,
    greatPlaceCircleStyle,
    greatPlaceCircleStyleHover,
    greatPlaceStickStyle,
    greatPlaceStickStyleHover,
    greatPlaceStickStyleShadow,
    K_CIRCLE_SIZE,
    K_STICK_SIZE
};