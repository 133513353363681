

import {
  FETCH_BLOG,
  FETCH_BLOG_SUCCESS,
  FETCH_BLOG_ERROR,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
	DELETE_CATEGORIES,
	DELETE_CATEGORIES_SUCCESS,
	DELETE_CATEGORIES_ERROR,
	DELETE_BLOG,
	DELETE_BLOG_SUCCESS,
	DELETE_BLOG_ERROR
} from "../constants";

const INITIAL_STATE = {
	loading: false,
	data: {},
	categories: {},
	error: null,
};

function blogReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
    case FETCH_BLOG:
      return { ...state, loading: true, error: null };
    case FETCH_BLOG_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_BLOG_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case FETCH_CATEGORIES:
      return { ...state, loading: true, error: null };
    case FETCH_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case FETCH_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case DELETE_CATEGORIES:
      return { ...state, loading: true, error: null };
    case DELETE_CATEGORIES_SUCCESS:
      const categories = Object.keys(state.categories).filter(cat => cat !== action.payload).map(item => state.categories[item]);
      return { ...state, loading: false, categories: categories};
    case DELETE_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case DELETE_BLOG:
      return { ...state, loading: true, error: null };
    case DELETE_BLOG_SUCCESS:
      const data = Object.keys(state.data).filter(cat => cat !== action.payload).map(item => state.data[item]);
      return { ...state, loading: false, data: data};
    case DELETE_BLOG_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case CREATE_CATEGORY:
      return { ...state, loading: true, error: null };
		case CREATE_CATEGORY_SUCCESS:
      return { ...state, loading: false, categories: Object.assign({}, ...state.categories, action.payload ) };
		case CREATE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}

export default blogReducer;