import {
	FETCH_BLOG,
	FETCH_BLOG_SUCCESS,
	FETCH_BLOG_ERROR,
	FETCH_CATEGORIES,
	FETCH_CATEGORIES_SUCCESS,
	FETCH_CATEGORIES_ERROR,
	CREATE_CATEGORY,
	CREATE_CATEGORY_SUCCESS,
	CREATE_CATEGORY_ERROR,
	DELETE_CATEGORIES,
	DELETE_CATEGORIES_SUCCESS,
	DELETE_CATEGORIES_ERROR,
	DELETE_BLOG,
	DELETE_BLOG_SUCCESS,
	DELETE_BLOG_ERROR
} from '../constants';

import { db } from "../firebase";

export const fetchBlogBegin = payload => ({ type: FETCH_BLOG });
export const fetchBlogSuccess = payload => ({ type: FETCH_BLOG_SUCCESS, payload });
export const fetchBlogError = payload => ({ type: FETCH_BLOG_ERROR, payload });

export const fetchCategoriesBegin = payload => ({ type: FETCH_CATEGORIES });
export const fetchCategoriesSuccess = payload => ({ type: FETCH_CATEGORIES_SUCCESS, payload });
export const fetchCategoriesError = payload => ({ type: FETCH_CATEGORIES_ERROR, payload });

export const deleteCategoriesBegin = payload => ({ type: DELETE_CATEGORIES });
export const deleteCategoriesSuccess = payload => ({ type: DELETE_CATEGORIES_SUCCESS, payload });
export const deleteCategoriesError = payload => ({ type: DELETE_CATEGORIES_ERROR, payload });

export const deleteBlogBegin = payload => ({ type: DELETE_BLOG });
export const deleteBlogSuccess = payload => ({ type: DELETE_BLOG_SUCCESS, payload });
export const deleteBlogError = payload => ({ type: DELETE_BLOG_ERROR, payload });

export const createCategoryBegin = payload => ({ type: CREATE_CATEGORY });
export const createCategorySuccess = payload => ({ type: CREATE_CATEGORY_SUCCESS, payload });
export const createCategoryError = payload => ({ type: CREATE_CATEGORY_ERROR, payload });

export function onGetBlog() {
	return dispatch => {
	dispatch(fetchBlogBegin());
	return db
		.onceGetBlog()
		.then(snapshot => {

		return dispatch(fetchBlogSuccess(snapshot.val() ? snapshot.val() : {}));
		})
		.catch(error => {
		return dispatch(fetchBlogError(error));
		});
	};
}

export function onGetCategories() {
	return dispatch => {
		dispatch(fetchCategoriesBegin());
		return db
			.onceGetCategories()
			.then(snapshot => {
				return dispatch(fetchCategoriesSuccess(snapshot.val() ? snapshot.val() : {}));
			})
			.catch(error => {
				return dispatch(fetchCategoriesError(error));
			});
	};
}

export function deleteCategory(id) {
	return dispatch => {
		dispatch(deleteCategoriesBegin());
		db
			.onDeleteCategory(id);
		return dispatch(deleteCategoriesSuccess(id));
	};
}

export function deleteBlog(id) {
	return dispatch => {
		dispatch(deleteBlogBegin());
		db
			.onDeleteBlog(id);
		return dispatch(deleteBlogSuccess(id));
	};
}

export function onCreateCategory(category) {
	return dispatch => {
		dispatch(createCategoryBegin());
		db.doCreateCategory(category)

		return db.onceGetCategories()
			.then(snapshot => {
				return dispatch(fetchCategoriesSuccess(snapshot.val() ? snapshot.val() : {}));
			})
			.catch(error => {
				return dispatch(fetchCategoriesError(error));
			});
	};
}